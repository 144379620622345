import { HttpClient } from '@angular/common/http';
import { inject, Injectable, Optional, signal } from '@angular/core';
import { ErrorResponse } from '../Models/Interfaces/errorResponse';
import { LoginResponse } from '../Models/Interfaces/loginResponse';
import { catchError, Observable, of } from 'rxjs';
import { CustomLocalStorageService } from './custom-local-storage.service';
import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

    errorResponse?:ErrorResponse;
    loginResponse?:LoginResponse;
    _baseLog="LoginService ";
    // memberAccessTypes:any={
    //                         "MSIT":[
    //                             "ADMIN-PAGE","CREATE-ELEMENT","UPDATE-ELEMENT","DELETE-ELEMENT","APPROVED-ELEMENT","ADMIN-VIEWS"
    //                         ],
    //                         "SUPERADMIN":[
    //                             "ADMIN-PAGE","CREATE-ELEMENT","UPDATE-ELEMENT","DELETE-ELEMENT","APPROVED-ELEMENT","ADMIN-VIEWS"
    //                         ],                            
    //                         "ADMIN":[
    //                             "ADMIN-PAGE","CREATE-ELEMENT","UPDATE-ELEMENT","ADMIN-VIEWS"
    //                         ]

    //                     };

    http=inject(HttpClient);
    
    userEmail:string|undefined;
    userPassword:string|undefined;
    
    loginUrl:string|null;
    ChangePasswordUrl:string|null;

    private userMemberType=signal("User");
    private userLoggedInTtileForGreetings=signal("");
    private _userLoggedInStatus=signal(false);
    private userLoggedInData=signal(new LoginResponse());

    constructor(@Optional() private localStorageService:CustomLocalStorageService){
       // //alert("Creating Login Service");
        this.loginUrl=sessionStorage.getItem("loginUrl");
        this.ChangePasswordUrl=sessionStorage.getItem("changepassword");
        this.userLoggedInTtileForGreetings.set("Login");
        this.applyIfLoggedIn();
    }

    setLoginStatus(status:boolean){
        this._userLoggedInStatus.set(status);
    }

    getLoginStatus():boolean{
        return this._userLoggedInStatus();
    }

    tryToLogin(userEmail:string,userPassword:string){
        this.userEmail=userEmail;
        this.userPassword=userPassword;
        
        this.callApi( this.userEmail,this.userPassword);
        ////alert("LoginService recv Email "+this.userEmail);

    }
    
    takeAllActionsForLogOut(){
        //alert(this._baseLog+"takeLogOutAction Logout Action Started");
        this.loginResponse=new LoginResponse();
       
        this.loginResponse.isLoggedIn=false;
        this.loginResponse.email_id=undefined;
        this.userLoggedInTtileForGreetings.set("Login");
        this.userLoggedInData.set( this.loginResponse);
        this.localStorageService.setData("Acess_Token","RAMSIYARAM");
        this.localStorageService.setData("LogInTime",undefined);
        this.setLoginStatus(false);
    }

    getLoggedInTime(){
        return this.localStorageService.getData("LogInTime");
    }

    applyIfLoggedIn(){
       
        let logInTime= this.localStorageService.getData("LogInTime");
       ////alert("Login Service Get Data Complete RAM "+logInTime+" Diff "+(this.getCurTimestamp()-Number(logInTime)));
        if(logInTime!=undefined&&(this.getCurTimestamp()<(Number(logInTime)+3000))){
            
            this.logginInFromStorageData();
        }else{
            ////alert("Login Service TNot Logged In RAM ");
        }
    }


    logginInFromStorageData(){
           
        this.loginResponse=new LoginResponse();

        let email=this.localStorageService.getData("email_id")
        this.loginResponse.email_id=email;
        this.userLoggedInTtileForGreetings.set(email);

        this.loginResponse.isLoggedIn=true;

        let userMemberType:string=this.localStorageService.getData("role_type");
        this.userMemberType.set(userMemberType);        
        this.loginResponse.role_type=userMemberType;

        this.loginResponse.access_token=this.localStorageService.getData("Acess_Token");
        this.localStorageService.setData("",this.userLoggedInData().expires_in);
        this.loginResponse.expires_in=this.localStorageService.getData("Expires_In");
        this.setLoginStatus(true);

        this.userLoggedInData.set(this.loginResponse); 
    }



    getCurTimestamp(){
        //const timestamp = Date.now()
        return Math.round(+new Date()/1000);;
    }

    callApi(email:string,password:string){
        if(this.loginUrl!=null){
        this.http.post(this.loginUrl,
            {
                "email": email,
                "password":password
            },{ responseType: 'json' }).subscribe(
                (response) => {  
                   
                    this.loginResponse=<LoginResponse>response;
                    // alert("Login Reponse "+JSON.stringify(this.loginResponse));
                    this.loginResponse.email_id=email;
                    this.localStorageService.setData("email_id",email);

                    this.userLoggedInTtileForGreetings.set(email);

                    this.loginResponse.isLoggedIn=true;
                    this.localStorageService.setData("IsLoggedIn","true");

                    this.userMemberType.set(this.loginResponse.role_type);
                    this.localStorageService.setData("role_type",this.loginResponse.role_type);
                    // this.loginResponse.role_type="MSIT";

                    this.userLoggedInData.set( this.loginResponse); 
                  
                    this.localStorageService.setData("Acess_Token",this.userLoggedInData().access_token);
                    this.localStorageService.setData("Expires_In",this.userLoggedInData().expires_in);

                    this.localStorageService.setData("LogInTime",this.getCurTimestamp());
                    this.setLoginStatus(true);
                    
                },
                (error) => {  
                    swal.fire("Not able to login",JSON.stringify(error),"error");
                    this.takeAllActionsForLogOut();
            
                    }
                );

            }
        
    }

    callChangePasswordApi(email:string,password:string,newPassword:string){
        if(this.ChangePasswordUrl!=null){
        this.http.post(this.ChangePasswordUrl,
            {
                "email": email,
                "oldPassword":password,
                "newPassword":newPassword
            },{ responseType: 'json' }).subscribe(
                (response) => {  
                   
                alert("Login response "+JSON.stringify(response))

                   
                    
                },
                (error) => {  
                    
                    if(error.status==202){
                        swal.fire("Password change Completed for ",email,"success");
                    }else{
                        swal.fire("Password change not success ",JSON.stringify(error),"error");
                    }
                    // alert("Login response "+JSON.stringify(error))
            
                    }
                );

            }
        
    }

    // getMemberAccessTypes(){
    //     return this.memberAccessTypes;
    // }

    getUserMemberType():string{
        return this.userMemberType();
    } 

    getLoggedInNameCustom():string{
        return this.userLoggedInTtileForGreetings();
    }

    getLoginDetails(){
        return this.userLoggedInData();
    }

}