<!-- <app-my-carousel [myURL]="homePageImageUrl"></app-my-carousel>
  -->


<app-gen-one-home-carousel></app-gen-one-home-carousel>

<div class="w-100 bg-primary ps-3 pe-3 pt-4 pb-4">
  <app-gen-one-list-view-static [listTitle]="institute" [fromHome]="stateComment"></app-gen-one-list-view-static>
  <div class="w-100 pt-2 pb-4"></div>
  <app-gen-one-list-view-static [listTitle]="scholarship" [fromHome]="stateComment"></app-gen-one-list-view-static>
  <div class="w-100  pt-2 pb-4"></div>
  <app-gen-one-list-view-static [listTitle]="course" [fromHome]="stateComment"></app-gen-one-list-view-static>
  <div class="w-100  pt-2 pb-4"></div>
  <app-gen-one-list-view-static [listTitle]="edutube" [fromHome]="stateComment"></app-gen-one-list-view-static>
  <div class="w-100  pt-2 pb-4"></div>
  <app-gen-one-list-view-static  [listTitle]="teacher" [fromHome]="stateComment"></app-gen-one-list-view-static>

</div>