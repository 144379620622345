
<!-- Button trigger modal -->
<!-- -->

<div class="modal bg-secondary" id="elementCreateUpdateOne" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg " role="document">
        <div class="modal-content "> 

            <div class="row justify-content-center align-items-center ">
                <div class="col col-12">
                <div class="card rounded-3 bg-secondary">
                    <img 
                    [src]="curImage"
                   
                    class="w-100" 
                    alt="Sample photo">
                    <div class="card-body p-4">
                    <h3 class="pb-2 ">Register New {{curElementType}}</h3>
                        
                        <div class="row">
                                    
                            <div class="col col-12 pb-2 pt-1" >
                                <label for="name">{{curElementType}} Name</label>

                            </div>
                            <div class="col col-12 pb-2 pt-1" >
                                
                                <input class="form-control" type="text"
                                placeholder="RAM"
                                (change)="checkMyStatus('name')"
                                (keypress)="checkMyStatus('name')"
                                    id="name" #name>
                            </div>
                        
                            <div class="col col-12 pb-2 pt-1">
                                <label for="Image">Image</label>
                                <input class="form-control" 
                                type="url"   
                                (focusout)="changeImage()"
                                (change)="checkMyStatus('image')"
                                (keypress)="checkMyStatus('image')"
                                placeholder="{{curImage}}"
                                id="image"
                                #image>
                            </div>
                            <div class="col col-12 pb-2 pt-1" *ngIf="isControlRequired('url')">
                                <label for="cururl">URL</label>
                                <input class="form-control" 
                                type="url"
                                placeholder="https://inGurukul.com"
                                (change)="checkMyStatus('url')"
                                (keypress)="checkMyStatus('url')"
                                id="url" #url>
                            </div>
                            <div class="col col-12 pb-2 pt-1" *ngIf="isControlRequired('contact')">
                                <label for="contact">Contact Details</label>              
                                <input class="form-control" type="text"
                                required
                                minlength="10"
                                maxlength="14"
                                (change)="checkMyStatus('contact')"
                                (keypress)="checkMyStatus('contact')"
                                type="number"
                                placeholder="91-9765432123"
                                    id="contact" #contact>
                            </div>
                            <div class="row"  *ngIf="isControlRequired('address')">
                                <div class="col col-12 pb-2 pt-1">
                                    <label for="address">Address Details</label>    
                                </div>
                                <div class="col col-12 pb-2 pt-1" >
                                
                                    <div data-mdb-input-init class="form-outline">
                                        <textarea 
                                        (change)="checkMyStatus('address')"
                                        (keypress)="checkMyStatus('address')"
                                        class="form-control" 
                                        placeholder="Durgapur,WestBengal,India"
                                        id="address" #address rows="3"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="isControlRequired('gender')">
                                <div class="col col-12 pb-2 pt-1"  >
                                    <label for="Gender" >Current Gender {{selectedGender}}</label>
                                </div>
                                <div class="col col-lg-2 col-md-3 col-sm-3 pt-2 pb-2 ps-0 pe-0" *ngFor="let gender of genders">
                                    <input type="radio" class="btn-check" name="gender" id="{{gender}}"  
                                    ng-checked="(gender==selectedGender)"
                                   (click)="setSelectedGender(gender)">
                                        <label class="btn btn-outline-primary" for="{{gender}}">{{gender}}</label>                   
                                </div>
                            </div>
                            <div class="row" id="tagBox">
                                <div class="col col-lg-12 col-md-12 col-sm-12 pt-2 pb-2" >
                                    <label for="allTags">InGurukul Tags </label>
                                </div>                
                                <div class="col col-lg-12 col-md-12 col-sm-12 pt-2 pb-2" >
                                    <div class="row">
                                        <div class="col pt-1 pb-1 ps-1" *ngFor="let tag of allTags">
                                            <input type="checkbox"  (click)="setAllTagSelected(tag)"                                             
                                            class="btn-check" name="tag" id="{{tag}}" 
                                            [checked]="isAtagSelected(tag)" >
                                            <label class="btn btn-outline-primary" for="{{tag}}">{{tag}}</label>    
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        <div class="row" id="etagBox" >
                                <div class="col col-lg-12 col-md-12 col-sm-12 pt-2 pb-2" >
                                    <label for="allTags">{{curElementType}} Tags</label>
                                </div>                
                                <div class="col col-lg-12 col-md-12 col-sm-12 " >
                                    <div class="row">
                                        <div class="col " *ngFor="let etag of curElementTags">
                                            <input type="checkbox" (click)="setETagSelected(etag)"  
                                            class="btn-check" name="etag" id="E-{{etag}}" 
                                            [checked]="isEtagSelected(etag)"
                                            autocomplete="off" >
                                            <label class="btn btn-outline-primary" for="E-{{etag}}">{{etag}}</label>    
                                        </div> 
                                    </div>
                                </div>
                        </div>
                        

                                                    
                            <div class="row  justify-content-center pt-3 pb-5">

                                <div class="col col-12">
                                        
                                    <form [formGroup]="paragraphForm"   >

                                        <div class="form-group">

                                            
                                                
                                                
                                            <div class="row pt-4">
                                                    <div fromArrayName="paras">
                                                        <h4>Paragraph </h4>
                                                    
                                                        <div class="form-group" *ngFor="let eachGroup of paragraphForm.get('paras')?.value;let i=index">
                                                            <!-- <input type="text" class="form-control" [formControlName]="i"> -->
                                                            
                                                            <form [formGroup]="eachGroup" >
                                                                <div class="form-group">
                                                                    
                                                                    <div class="row">
                                                                        <div class="row ps-4 pt-3 pb-4 justify-content-start">
                                                                            <div class="col col-12 pt-2 pb-2">
                                                                                <label for="paraName">paraName </label>
                                                                                <input class="form-control" type="text"
                                                                                    formControlName="paraName"
                                                                                    (focusout)="updateParagraph('head',i,$event)"
                                                                                    id="paraName"
                                                                                   > 
                                                                            </div>
                                                                    
                                                                            <div class="col col-12 pt-2 pb-2">
                                                                                <label for="paraBody">paraBody</label>
                                                                                <!-- <input class="form-control" type="text"
                                                                                    formControlName="paraBody"
                                                                                    id="paraBody"> -->
                                                                                <div data-mdb-input-init class="form-outline">
                                                                                    <textarea class="form-control" 
                                                                                    formControlName="paraBody" 
                                                                                    id="paraBody" 

                                                                                    (focusout)="updateParagraph('body',i,$event)"
                                                                                    rows="6"></textarea>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row ps-4 pt-3 pb-4 justify-content-end">
                                                                        <div class="col col-lg-4 col-md-6 col-sm-12 pt-2 pb-2">
                                                                            <button class="btn btn-primary" 
                                                                            (click)="removePara(i)"
                                                                            
                                                                            >Remove Para </button>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    
                                                    
                                                        
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div class="row ps-4 pt-3 pb-4 justify-content-center w-100">                            
                                    <button class="btn btn-primary" 
                                    (click)="addPara()"                                    
                                    >Add Para </button>           
                            </div>
                        </div>
                        
                        <div class="col col-8" *ngIf="formCanBeSubmitted">
                            <div class="row pt-5 pb-2 justify-content-end ">
                                
                                    <button type="button" class="btn bg-success " (click)="submitAction()" >
                                        Submit Form
                                    </button> 
                                
                            </div> 
                        </div> 
                        <div class="col col-5">
                            <div class="row pt-5 pb-2 justify-content-end ">
                                
                                    <button type="button" class="btn btn-outline-danger "
                                    (click)="resetForm()" >
                                        Reset Form Data
                                    </button> 
                                
                            </div> 
                        </div> 
                       
                        <div class="col col-5">
                            <div class="row pt-5 pb-2 justify-content-end ">
                                
                                    <button type="button" class="btn btn-outline-danger "
                                    (click)="closeModalNow()" >
                                        Close This Form
                                    </button> 
                                
                            </div> 
                        </div> 
                       
                       
                    </div>
                </div>
                </div>
            </div>

            <!-- </div> -->
        <!-- </section> -->


    </div>
</div>
</div>

<!-- <div class="container ">
    <div class="row ">
        <div class="col-fluid col-lg-3 col-md-4 col-sm-12 p-1">
            <button type="button" class="btn btn-primary " (click)="openModalNow()">
                Creating
            </button>
        </div>
    </div>
</div> -->
