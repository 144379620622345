






<div class="container-fluid pt-3 pb-5">
  <div class="row justify-content-start  " *ngIf="isAdminLogins()">
  </div>
  <div class=" text-center  text-secondary">
    <p class="h4 ">{{elementType}} - {{curElementName}}</p>
  </div>
<div>

<app-my-carousel [myURL]="currentElementImageUrl" [elementType]="elementType"></app-my-carousel>  


 <app-my-text-area  [elementType]="elementType"> </app-my-text-area>



<!-- <button (click)="openDialog()">Filters RamJi</button> -->
<!-- <app-panditya-filter [curElementType]="elementType"></app-panditya-filter> -->

<!-- <app-my-filters/> -->


<app-admin-controls [curElementType]="elementType"  *ngIf="isAdminLogins()"></app-admin-controls>
