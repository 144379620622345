
<div id="carouselExampleInterval"  class="carousel slide shadow-lg" data-bs-ride="carousel" >
    <div class="carousel-inner" >
      <!-- <div class="carousel-item" data-bs-interval="5000">
        <img src="/assets/inspiration.png" style="height: 60vh;" class="d-block w-100"  alt="...">
      </div> -->
      <div class="carousel-item active"   data-bs-interval="5000">
        <img src={{urlOne}} style="height: 60vh;" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item" data-bs-interval="5000">
        <img src={{urlSecond}} style="height: 60vh;" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item" data-bs-interval="5000">
        <img src={{urlThird}} style="height: 60vh;" class="d-block w-100"  alt="...">
      </div>
      <div class="carousel-item" data-bs-interval="5000">
        <img src={{urlFourth}} style="height: 60vh;" class="d-block w-100"  alt="...">
      </div>
      <div class="carousel-item" data-bs-interval="5000">
        <img src={{urlFive}} style="height: 60vh;" class="d-block w-100"  alt="...">
      </div>

    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>