import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-gen-one-home-carousel',
  imports: [CommonModule],
  templateUrl: './gen-one-home-carousel.component.html',
  styleUrl: './gen-one-home-carousel.component.scss'
})
export class GenOneHomeCarouselComponent {
  cuUrl:string="https://m.media-amazon.com/images/S/pv-target-images/acf9b9510a723d3590799c6ed29afe449dba0947ee6e8fb2fab99f9e63b1ca3f._SX1080_FMjpg_.jpg";
  
  urlOne=  "https://www.drishtiias.com/images/blogs/Celebrating-the-Legacy-of-a-Warrior-Subhash-Chandra-Bose.png";
  urlSecond=  "https://blog.bluelupin.com/wp-content/uploads/Picture-1.jpg";
  urlThird=  "https://blog.scstechindia.com/wp-content/uploads/2022/04/artificial-intelligence-in-education-industry.png";
  urlFourth=  "https://hindi.cdn.zeenews.com/hindi/sites/default/files/PL-02.jpg";
  urlFive=  "https://www.ptvaenglishmediumandheri.com/wp-content/uploads/2019/07/manache-shlok-848x548.jpg";

  // urlOne=  "/assets/home1.png";
  // urlSecond=  "/assets/home2.png";
  // urlThird=  "/assets/home3.png";
  // urlFourth=  "/assets/home4.png";
  // urlFive=  "/assets/home5.png";

}
