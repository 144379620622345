import { Component, effect, Input } from '@angular/core';
import { PageModels } from '../../Models/Interfaces/pageModels';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PageGenOneService } from '../../Services/page-gen-one.service';

@Component({
  selector: 'app-page-gen-one',
  imports: [FormsModule,CommonModule,ReactiveFormsModule],
  templateUrl: './page-gen-one.component.html',
  styleUrl: './page-gen-one.component.scss'
})
export class PageGenOneComponent {

  // @Input() 
  set pageName(value){
      this._pageName = value; 
  }
  get pageName() {
      return this._pageName; 
  }
  _pageName:string|undefined;

  pageUpdateTime:string="";
  paragraphs:Map<string,string>|undefined;
  pageModels:PageModels|undefined;

  pageGenOneService:PageGenOneService=PageGenOneService.getInstance();

  constructor(){
    effect(()=>{
            this.pageModels=this.pageGenOneService.getCurPageData();
              //  alert(this.pageModels.pageName+" Page Updated >> "+JSON.stringify(this.pageModels));
            this.setPageStatus();
    });
  }

  isNamePresent(){
    return (this.pageModels?.pageName!=undefined&&this.pageModels.pageName.length>3);
  }

  setPageStatus(){
    if(this.pageModels!=undefined&&this.isNamePresent()){
      this.setPageData();
    this.showModalNow();
    }
  }

  getParagrapBreakings(paragraph:string){
    return paragraph.split("\n");
  }

  getTitle(){
    return (this.pageModels!=undefined&&this.pageModels.pageName!=undefined)?this.pageModels.pageName:"";
  }

  getParagraps(){
    
    return this.pageModels?.allParagraphs;

    //  return ["We are working on this","Allow some time here"];
    //! return (this.pageModels!=undefined&&this.pageModels.allParagraphs!=undefined)?this.pageModels.allParagraphs:Map<string,string>;
  }



  getCreationTime(){
    return (this.pageModels!=undefined&&this.pageModels.created_on!=undefined)?this.pageModels.created_on:"";
  }

  getData(){

  }

  closeModalNow(){       
    const myModal=document.getElementById('pageOneDiisplay');
    if(myModal!=null){
      myModal.style.display='none';
    }
  }

  setPageData(){
    // this.pageModels=new PageModels("",pageName,"2024-03-08",this.getPara());
    
    
    // alert("After setting new "+this.pageName);
  }

  showModalNow(){
   
    const myModal=document.getElementById('pageOneDiisplay');
    if(myModal!=null){
      myModal.style.display='block';      
    }
  }
  
  openModalNow(pageName:string,thisElementType:string){
    //  alert("Calling for new "+pageName);
    this.pageGenOneService.setCurPageName(pageName);
    
  }

}
