import { HttpClient } from '@angular/common/http';
import { Component, effect, inject, Input } from '@angular/core';
import { SelectedElementService } from '../../Services/selected-element.service';

import { CommonModule } from '@angular/common';
import { TextAreaElement } from '../../Models/Interfaces/textAreaElement';
import { AdminViewService } from '../../Services/admin-view.service';
import { FormsModule } from '@angular/forms';



@Component({
  selector: 'app-my-text-area',
  imports: [FormsModule,CommonModule],
  templateUrl: './my-text-area.component.html',
  styleUrl: './my-text-area.component.scss'
})
export class MyTextAreaComponent {
  _baseLog="MyTextAreaComponent";
  curElement?:TextAreaElement;
  isElementPresentNow:boolean=false;
  currentViewService:AdminViewService=AdminViewService.getInstance();
  //! currentViewType:string;
  elementId?:string="";

  @Input() 
  set elementType(value: string) {
    this._elementType = value; 
  }
  get elementType():string {    
    return this._elementType;     
  }
  _elementType:string="";
  curElementAllTags:string[]=["RAMSITA"];
  curElementSpecificTags:string[]=["RAMSITA"];
  createdUrl:string|undefined;


  http=inject(HttpClient);

 constructor(public elementUpdateService:SelectedElementService ){
  //alert("TextArea - View change effect"+this.currentViewService.getView());
  //! this.currentViewType=this.currentViewService.getView();
  if(this.elementUpdateService.getSelectedItem().id!=undefined){
    this.elementId=this.elementUpdateService.getSelectedItem().id;
  }

  this.getAllElements();
    effect(()=>{
      this.elementId=this.elementUpdateService.getSelectedItem().id;
      this.getAllElements();
    })
    effect(()=>{
      ////alert("TextArea - View change effect"+this.currentViewService.getView());
      // ! this.currentViewType=this.currentViewService.getView();
      //! this.getAllElements();
    })
  }
 
  getAddress():any{
    return (this.curElement?.address ==undefined)? false:this.curElement.address;
  }
  
  getContact():any{
    return (this.curElement?.contact ==undefined)? false:this.curElement.contact;
  }

  getGender():any{
    return (this.curElement?.gender ==undefined)? false:this.curElement.gender;
  }

  getUrl():any{
    return (this.curElement?.url ==undefined)? false:this.curElement.url;
  }

  getParaNames():any{
    return (this.curElement?.paraname ==undefined)? []:this.curElement.paraname;
  }

  getParaBody():any{
    return (this.curElement?.parabody ==undefined)? []:this.curElement.parabody;
  }

  getId():any{
    return (this.curElement?.id ==undefined)? false:this.curElement.id;
  }

  getAllTags():any{
    return (this.curElement?.allTags ==undefined)? false:this.curElement.allTags;
  }

  // getDeleted():any{
  //   return (this.curElement?.deleted ==undefined)? false:this.curElement.deleted;
  // }

  getCreated_on():any{
    return (this.curElement?.created_on ==undefined)? false:this.curElement.created_on;
  }

  getLast_updated():any{
    return (this.curElement?.last_updated ==undefined)? false:this.curElement.last_updated;
  }

  getApprovedByUser():any{
    return (this.curElement?.approvedByUser ==undefined)? false:this.curElement.approvedByUser;
  }

  getCreatedByUser():any{
    return (this.curElement?.createdByUser ==undefined)? false:this.curElement.createdByUser;
  }

  getsearchCount():any{
    return (this.curElement?.searchCount ==undefined)? false:this.curElement.searchCount;
  }

  isElementPresent(){
    return  (this.curElement?.name!=undefined)? true:false;
  }

  getParagrapBreakings(paragraph:string){
    return paragraph.split("\n");

  }

  getParagraps():Map<string,string>{

    let paras=new Map<string,string>()
    const paraNames=this.getParaNames();
    const parabody=this.getParaBody();
    // alert(this._baseLog+"We have Para test "+JSON.stringify(paraNames)+"-- "+JSON.stringify(parabody));

    for (let i = 0; i < parabody.length; i++) {
     
      if((<string>parabody[i]).includes("<<**RAM**>>")){
        let curData=(<string>parabody[i]).split("<<**RAM**>>");
        paras.set(curData[0], curData[1]);
      }
      else{
        paras.set("Please recreate the Element "+paraNames[i],"Create the Element again Please "+parabody[i]);
      }
    
    }
   
    return paras;
  }
  

  getIdUrl():string{
    return sessionStorage.getItem("userViewUrlPref")+"/"+
      this.elementType.toLocaleLowerCase()+"/id/"+this.elementId;
  }

  checkParaPresent(){

  }

  goToLink(){
    window.open(this.getUrl(), "_blank");
  }

  getTagsAssigned(){ 
    if(this.elementType.toLowerCase()=="institute"){
      // alert(this._baseLog+"Institute Invoked");
      this.curElementSpecificTags=(this.curElement?.institueTags!=undefined)?this.curElement.institueTags:[];
    }
    if(this.elementType.toLowerCase()=="scholarship"){
      this.curElementSpecificTags=(this.curElement?.scholarshipTags!=undefined)?this.curElement.scholarshipTags:[];
    }
    if(this.elementType.toLowerCase()=="course"){
      this.curElementSpecificTags=(this.curElement?.courseTags!=undefined)?this.curElement.courseTags:[];
    }
    if(this.elementType.toLowerCase()=="teacher"){
      this.curElementSpecificTags=(this.curElement?.teacherTags!=undefined)?this.curElement.teacherTags:[];
    }
    if(this.elementType.toLowerCase()=="edutube"){
      this.curElementSpecificTags=(this.curElement?.edutubeTags!=undefined)?this.curElement.edutubeTags:[];
    }
    
    this.curElementAllTags=(this.curElement?.allTags!=undefined)?this.curElement.allTags:[];
  }

  getAllElements(){
    this.isElementPresentNow=false;
    this.curElement=new TextAreaElement();

    if(this.elementType!=undefined&&this.elementType.length>3&&this.elementId!=undefined&&this.elementId.length>3){

      
      if(this.elementUpdateService.getSelectedItem().allTags==undefined){
        // alert(this._baseLog+" getAllElements User View API Call "+this.getIdUrl());
          this.http.get(this.getIdUrl()).subscribe((res:any)=>{
            this.curElement= res;
            this.getParagraps();
            this.isElementPresentNow=true;
            // alert("Element Update for User View "+JSON.stringify(this.curElement));
          }    
        );
      }else{
        this.curElement=this.elementUpdateService.getSelectedItem();
        this.getParagraps();
        // alert(this._baseLog+" getAllElements Admin View "+JSON.stringify(this.curElement));
        this.getTagsAssigned();
        this.isElementPresentNow=true;
      }
    }
  }

}
