import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomLocalStorageService {

  appName:string="InGurukul";
  // constructor() { }
  setData(key:any,value:any){
    //alert("Set LocalStorage key"+key+">> Value"+value);
    // localStorage.setItem(this.appName+key,value);
    try{
     localStorage.setItem(this.appName+key,value);
   // sessionStorage.setItem(this.appName+key,JSON.stringify(value));
    }catch(e){alert("LocalStorageService store error"+key)}
  }

  getData(key:any):any{
    try{
    // let data=localStorage.getItem(JSON.parse(this.appName+key));
    
    let data=localStorage.getItem(this.appName+key);
    // alert("LocalStorage Get key"+key+">> Value"+data);
    return data;
  }catch(e){alert("LocalStorageService get error"+key)}
  }

  deleteData(key:any):any{
    localStorage.removeItem(this.appName+key);
  }

}
