import { Component, effect, Inject, Input, OnInit } from '@angular/core';
import { ElementETagService } from '../../Services/element-etag.service';
import { ElementTagService } from '../../Services/element-tag.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FilterDynamicSearchService } from '../../Services/filter-dynamic-search.service';

@Component({
  selector: 'app-panditya-filter',
  imports: [FormsModule,CommonModule],
  templateUrl: './panditya-filter.component.html',
  styleUrl: './panditya-filter.component.scss'
})
export class PandityaFilterComponent {

  // @Input() 
  // set dynamicSearch(value: string) 
  // {
  //   ////////alert(this._baseLog+"List-Title change request new "+value);
   
  // }
  // get dynamicSearch():string {
  //   return this._dynamicSearch; 
  // }
  // _dynamicSearch:string="List-Unknown";


  allTags:string[]=[];
  stringArray:string[]=["RAM","SITA"];
  curElementTags:string[]=[];
  tagLen=4;

  allTagSelected:string="POPULAR";
  eTagSelected:string="";
  selectedName:string="";
  selectedAddress:string="";


  // @Input() 
  set curElementType(value: string) 
  {
   
    //////alert("Filter Received New Type "+value);
    this._curElementType = value; 
    //alert("Filter started with "+this.dynamicSearchService.getElementType());
    this.updateElementTags();
  
  }
  get curElementType():any {
    return (this._curElementType==undefined)?false:this._curElementType; 
  }
  _curElementType:any=undefined;
  
  

  constructor(
    private dynamicSearchService:FilterDynamicSearchService,
    private inHouseETagService:ElementETagService,
    private inHouseTagService:ElementTagService,
    ){
    
    try{
        //  !this.dynamicSearchService.setElementType(this.curElementType);
        this.curElementType=dynamicSearchService.getElementType();
        this.inHouseETagService.setElementTags(this.curElementType);
      }catch(error){
        ////alert("FILTER error check "+error);
      }
      this.curElementTags=this.inHouseETagService.getCurrentElementTag();
      effect(()=>{
        this.allTags=this.inHouseTagService.getAllTag();
      });
      effect(()=>{
        this.curElementType=dynamicSearchService.getElementType();
        this.inHouseETagService.setElementTags(this.curElementType);
      });
      effect(()=>{
        //
         
        this.curElementTags=this.inHouseETagService.getCurrentElementTag();
       // ////alert("Filter Comp- ETags "+this.curElementTags);
          //////alert("Effect Taken Place"+this.curElementTags);
         // this.tagLen=this.curElementTags.length;
          // this.currentElementImageUrl=this.selectedElementSuper.image;
            // this.currentElementId=this.selectedElementSuper.id;
            // this.curElementName=this.selectedElementSuper.name;
            
          })
  }

  updateElementTags(){
   
     
    
    this.inHouseETagService.setElementTags(this.curElementType);
    
    ////alert("RAMRAM updateElementTags"+this.curElementType);
  }

  closeModalNow(){
    const myModal=document.getElementById(this.curElementType+"-UserFilterModal");

    if(myModal!=null){
      myModal.style.display='none';
    }
  }

  openModalNow(){
    const myModal=document.getElementById(this.curElementType+"-UserFilterModal");
    if(myModal!=null){
      myModal.style.display='block';
    }
  }

  ngOnInit(): void{
    // ////alert("RAM list-ngOnInit");
   
   }

   eTagChange(tagName:string){
      if(this.eTagSelected!=tagName){
        this.eTagSelected=tagName;
      }else{
        this.eTagSelected="";
      }
   }
   allTagChange(tagName:string){
      if(this.allTagSelected!=tagName){
        this.allTagSelected=tagName;
      }
      else{        
        this.allTagSelected="";
      }
   }

   onSearchRequest(){
    ////alert("Filter-Search Data selected Name "+this.selectedName+"-"+this.selectedAddress+"---"      +this.allTagSelected+"--"+this.eTagSelected);
      let searchProperty =  new Map<string, string>();
      if(this.selectedName.length>1){
        searchProperty.set("name",this.selectedName);
      }
      if(this.selectedAddress.length>2){
        searchProperty.set("address",this.selectedAddress);
      }
      if(this.allTagSelected.length>1){
        searchProperty.set("tag",this.allTagSelected);
      }
      if(this.eTagSelected.length>2){
        searchProperty.set("etag",this.eTagSelected);
      }


      this.dynamicSearchService.updateFilterData(searchProperty);
      this.closeModalNow();
   }

   nameChange(name:string){
    this.selectedName=name;
   }
   
   addressChange(address:string){
    this.selectedAddress=address;
   }

}
