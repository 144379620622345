import { CommonModule } from '@angular/common';
import { Component, effect, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ElementETagService } from '../../Services/element-etag.service';
import { ElementTagService } from '../../Services/element-tag.service';
import { APICallerGenOneService } from '../../Services/apicaller-gen-one..service';
import { SelectedElementService } from '../../Services/selected-element.service';
import { AdminViewService } from '../../Services/admin-view.service';
import { TextAreaElement } from '../../Models/Interfaces/textAreaElement';
import { ElementFormComponent } from '../element-form/element-form.component';
import { LoginService } from '../../Services/login.service';
import swal from 'sweetalert2';
@Component({
  selector: 'app-admin-controls',
  imports: [FormsModule,CommonModule,ReactiveFormsModule,ElementFormComponent],
  templateUrl: './admin-controls.component.html',
  styleUrl: './admin-controls.component.scss'
})
export class AdminControlsComponent {

  _baseLog="AdminControlsComponent";
  print(text:string){
    alert(this._baseLog+" "+text);
  }
  _curApproveCount="";
  _curNotApproveCount="";
  @Input() 
    set curElementType(value: string) 
    {
     
      //////alert("Filter Received New Type "+value);
      this._curElementType = value; 
      this.inHouseETagService.setElementTags(this._curElementType);
      
      this.getApprovedTotalCount();
      this.getNotApprovedTotalCount();
      // this.currentElementForm=this.getinitialGroup();
      // this.getElementProperties();

     // this.adminViewService.setElementType(this._curElementType);
    }
    get curElementType():string {
      return this._curElementType; 

    }
    _curElementType:string="Institute";
  
    adminMemberType="ADMIN";
    // curElementType:string="Institute";
    genders:string[]=["FEMALE","MALE","OTHERS"];
    
    allTags:string[]=[];
    curElementTags:string[]=[];
    selectedAllTags:string[]=[];
    selectedElementTags:string[]=[];
    selectedGender="FEMALE";
    curSelectedElement:TextAreaElement;
   
    

    currentElementForm:FormGroup=this.getinitialGroup();
    genOneApiCaller:APICallerGenOneService=APICallerGenOneService.getInstance();
   
    getinitialGroup():FormGroup
    {
      return new FormGroup({      
        name:new FormControl(null,[Validators.required]),
        image: new FormControl(null,[Validators.required]),
        allTags:new FormArray([]),
        paras:new FormArray([])
      });
    }
  curElementForm:ElementFormComponent;

  // constructor(private inHouseETagService:ElementETagService,private inHouseTagService:ElementTagService){
  //   

    constructor(
      private loginService:LoginService,
      public selectedElementService:SelectedElementService, 
      private inHouseETagService:ElementETagService,
      private inHouseTagService:ElementTagService,
      private adminViewService:AdminViewService){
        this.curSelectedElement=selectedElementService.getSelectedItem();
        this.curElementForm=new ElementFormComponent(inHouseETagService,inHouseTagService,selectedElementService);
      
        this.adminMemberType=this.loginService.getUserMemberType();
        try{
        
        this.curElementTags=this.inHouseETagService.getCurrentElementTag();
        
        this.closeModalNow();
        effect(()=>{
            this.allTags=this.inHouseTagService.getAllTag();
        });
        effect(()=>{
          this.adminMemberType=this.loginService.getUserMemberType();
        });
        effect(()=>{
          //
            this.curElementTags=this.inHouseETagService.getCurrentElementTag();
            // this.print("All Ele Tags "+this.curElementTags);
        });
        effect(()=>{
          this.curSelectedElement=selectedElementService.getSelectedItem();
          // alert(this._baseLog+" Element Change effect");
        });

        effect(()=>{
          this._curApproveCount=JSON.stringify(this.genOneApiCaller.getCurApproveCount());
          this._curNotApproveCount=JSON.stringify(this.genOneApiCaller.getCurNotApproveCount());
        });

     
      }catch(e:any){
        this.print("constructor "+e);
      }
    }
    
    haveSuperAdminAccess(){
      return (this.adminMemberType=="MSIT"||this.adminMemberType=="SUPERADMIN");
    }

    canIShowDeleteButton(){
      // alert("Approved Status to set "+JSON.stringify(this.curSelectedElement.approvedByUser));
      return (this.curSelectedElement.name!=undefined&&(this.curSelectedElement.approvedByUser==undefined||
        this.curSelectedElement.approvedByUser==null))?true:false;
    }

    canIShowApproveButton(){
      // alert("Approved Status to set "+JSON.stringify(this.curSelectedElement.approvedByUser));
      return (this.curSelectedElement.name!=undefined&&(this.curSelectedElement.approvedByUser==undefined||
        this.curSelectedElement.approvedByUser==null))?true:false;
    }

    getadminUrlPrefix(){
      return (sessionStorage.getItem("adminelemgmt")!=null)?sessionStorage.getItem("adminelemgmt"):"URLNOTFOUND adminelemgmt";
    }

    getsuperAdminUrlPrefix(){
      return (sessionStorage.getItem("supadminelemgmt")!=null)?sessionStorage.getItem("supadminelemgmt"):"URLNOTFOUND adminelemgmt";
    }
  
    //! All API Calls 

    resetUrl(){
      try{
        let curUrl=this.adminViewService.getCurrentAdminURL();
        this.adminViewService.updateSameAdminQuery(undefined);
        // alert("Done Reset URL "+this.adminViewService.getCurrentAdminURL());
        this.adminViewService.updateSameAdminQuery(curUrl);
  
        // alert("Done Reset URL ");
      }catch(e){alert("Error Rest URL "+e);}
    
      
    }

    getCurElementName(){
      return ((this.curSelectedElement.name+"").length>35)?
            (this.curSelectedElement.name+"").slice(0,35)+"...":
            (this.curSelectedElement.name+"");
    }

    deleteElement(){
      // this.resetUrl();
       swal.fire({
              title: "Do you want to Delete "+this.selectedElementService.getSelectedItem().name+" ?",
              showDenyButton: true,
              confirmButtonText: "Delete",
              denyButtonText: `Close`,
              icon:"question"
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                this.resetUrl();
                this.genOneApiCaller.callDeleteApi("Delete Element Request   "+
                this.curElementType,this.getsuperAdminUrlPrefix()+this.curElementType.toLowerCase()+"/delete?id="+this.selectedElementService.getSelectedItem().id);
                this.getCounts(); 
              }
            });
      
    }

    approveElement(){

      swal.fire({
        title: "Do you want to Approve "+this.selectedElementService.getSelectedItem().name+" ?",
        showDenyButton: true,
        confirmButtonText: "Approve",
        denyButtonText: `Close`,
        icon:"question"
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.resetUrl();
          this.genOneApiCaller.callApproveApi("Approved Element Request  "+   
            this.curElementType,this.getsuperAdminUrlPrefix()+this.curElementType.toLowerCase()+"/approve/id/"+this.selectedElementService.getSelectedItem().id);
           this.getCounts(); 
        }
      });


     
    }

    createdApprovedState(){
      // this.adminViewService.updateAdminQuery(""+this.getadminUrlPrefix()+"/allapproved");
      this.adminViewService.updateAdminQuery("/allapproved");
    }

    createdNotApprovedState(){
      // this.adminViewService.updateAdminQuery(""+this.getadminUrlPrefix()+"/allnotapproved");
      this.adminViewService.updateAdminQuery("/allnotapproved");
   
    }

    getCounts(){
      this.getApprovedTotalCount();
      this.getNotApprovedTotalCount();
    }

    getApprovedTotalCount(){
      //////alert("Ready to Call "+this.getsupAdminUrlPrefix()+this.curElementType.toLowerCase()+"/allapprovedcount");
    
      this.genOneApiCaller.callApprovedCount("All Approved Count of "+
        this.curElementType,this.getadminUrlPrefix()+this.curElementType.toLowerCase()+"/allapprovedcount");
    }

    getNotApprovedTotalCount(){
      //////alert("Ready to Call "+this.getsupAdminUrlPrefix()+this.curElementType.toLowerCase()+"/allnotapprovedcount");
      this.genOneApiCaller.callNotApprovedCount("All Not Approved Count of "+this.curElementType,
        this.getadminUrlPrefix()+this.curElementType.toLowerCase()+"/allnotapprovedcount");
    }


    //! Finish API Calls

    //! New Implementation
  
    
    closeModalNow(){
      const myModal=document.getElementById('elementCreateUpdate');
  
      if(myModal!=null){
        myModal.style.display='none';
      }
    }
  
    openModalNow(status:string){
     
      this.curElementForm.openModalNow(status,this.curElementType);
      ////alert("Creation Form Called");
      // const myModal=document.getElementById('elementCreateUpdate');
      // if(myModal!=null){
      //   ////alert("Creation Form Called Trying Block");
      //   myModal.style.display='block';
      //   ////alert("Creation Form Called RAM");
      // }
    }
  
    // setSelectedGender(gender:string){
    //   this.selectedGender=gender;
    // }
  
    // setAllTagSelected(tagName:string){
    //   if(this.selectedAllTags.indexOf(tagName)>=0){
    //     this.selectedAllTags=this.selectedAllTags.filter(tag=>tag!=tagName);
    //   }
    //   else{
    //     this.selectedAllTags.push(tagName);
    //   }
    // }
  
    // setETagSelected(tagName:string){
    //   if(this.selectedElementTags.indexOf(tagName)>=0){
    //     this.selectedElementTags=this.selectedElementTags.filter(tag=>tag!=tagName);
    //   }
    //   else{
    //     this.selectedElementTags.push(tagName);
    //   }
    // }
  
    // addAllTags(){
    //   // ////alert("Updated the All Tag");
    //   let tagAR=[];
    //   for(let i=0;i<this.allTags.length;i++){
    //     const ncon=new FormControl(this.allTags[i]);
    //     // tagAR.push(ncon);
    //     (<FormArray>this.currentElementForm.get("allTags")).push(ncon);
    //   }
    //   // this.currentElementForm.setValue({allTags:tagAR});
    //   //////alert("Updated the All Tag");
    //   // this.currentElementForm.reset();
    // }
  
    // addETags(){
    //   // ////alert("Add "+this.curElementType+" Adding ETags");
    //   for(let i=0;i<this.curElementTags.length;i++){
    //     const ncon=new FormControl(this.curElementTags[i]);
    //     (<FormArray>this.currentElementForm.get("elementTags")).push(ncon);
    //   }
    //   //this.currentElementForm.patchValue({name:""});
  
    // }
  


  
  
    // getElementProperties(){
      
    //   this.print("Adding Property Specific for "+this.curElementType);
    //   if(this.curElementType.toLowerCase()=="instutute"){
    //     this.addInstituteControls();      
    //     return;
    //   }else if(this.curElementType.toLowerCase()=="edutube"){
    //     this.addEdutubeControls();
    //     return;
    //   }
    //   else if(this.curElementType.toLowerCase()=="teacher"){
    //     this.addTeacherControls();
    //     return;
    //   }
    //   else if(this.curElementType.toLowerCase()=="scholarship"||this.curElementType.toLowerCase()=="course"){
    //     this.addCourseScholarshipControls();     
    //     return;
    //   }
    
    // }
  
  
  
  
  
  
  
  
    // addInstituteControls(){
    //   // ////alert("addInstituteControls ");
    //   this.currentElementForm.addControl("address",new FormControl(null,[Validators.required]));
    //   this.currentElementForm.addControl("contact",new FormControl(""));
    //   this.currentElementForm.addControl("url",new FormControl(""));
    //   this.currentElementForm.addControl("elementTags",new FormArray([new FormControl("BEST"),new FormControl("POPULAR")]));
    // }
  
    // addCourseScholarshipControls(){
    //   this.currentElementForm.addControl("address",new FormControl(null,[Validators.required]));
    //   this.currentElementForm.addControl("url",new FormControl(""));
    //   this.currentElementForm.addControl("elementTags",new FormArray([new FormControl("BEST"),new FormControl("POPULAR")]));
    // }
  
    // addTeacherControls(){
    //   this.currentElementForm.addControl("address",new FormControl(null,[Validators.required]));
    //   this.currentElementForm.addControl("contact",new FormControl(""));
    //   this.currentElementForm.addControl("gender",new FormArray([new FormControl("MALE"),new FormControl("FEMALE")]));
    //   this.currentElementForm.addControl("elementTags",new FormArray([new FormControl("BEST"),new FormControl("POPULAR")]));
    // }
  
    // addEdutubeControls(){
    //   this.currentElementForm.addControl("url",new FormControl(""));
    //   this.currentElementForm.addControl("elementTags",new FormArray([new FormControl("BEST"),new FormControl("POPULAR")]));
    // }
    // isRequired(controlName:string){
    //   return this.currentElementForm.contains(controlName);
    // }
  
    //! End of New
  
  
    // removePara(index:number){
    //   // ////alert("EForm remove para req "+index);
    //   (<FormArray>this.currentElementForm.get("paras")).removeAt(index);
    // }
    // addPara(){
    //   // const ncon=new FormControl(null);
    //   // (<FormArray>this.currentElementForm.get("paras")).push(ncon);
  
    //   const paraGroup=new FormGroup({
    //     paraName:new FormControl(null,Validators.required),
    //     paraBody:new FormControl(null,Validators.required),
       
    //   });
    //   (<FormArray>this.currentElementForm.get("paras")).push(paraGroup);
    // }
  
  
    // saveElement(){
    //   this.print("Submit Event From Register"+JSON.stringify(this.currentElementForm.get("paras")?.value)+"Tags Selection "+this.selectedAllTags+"==="+this.selectedElementTags+" Gender "+this.selectedGender);
    //   //!alert(JSON.stringify(this.currentElementForm.value));
    //   //!alert("Tags Selection "+this.selectedAllTags+"==="+this.selectedElementTags+" Gender "+this.selectedGender);
    //   // if(this.currentElementForm!=null){
    //   //   //const value=(this.currentElementForm?.get("name")==null)?"":this.currentElementForm.get("name").value;
    //   //  // const value=this.currentElementForm.getRawValue().attribute();
    //   //   ////alert("Not Null"+this.currentElementForm.contains("url"));
    //   // }
    // }

  

  // curElementForm:ElementFormComponent;

  // constructor(private inHouseETagService:ElementETagService,private inHouseTagService:ElementTagService){
  //   this.curElementForm=new ElementFormComponent(inHouseETagService,inHouseTagService);
  // }

  // openCreationModalNow(){
  //   this.curElementForm.openModalNow();
  //   ////alert("Open Creation Modal");
  // }


}





// <div class="modal " id="elementCreateUpdate" tabindex="-1" role="dialog">
//     <div class="modal-dialog modal-lg " role="document">
//       <div class="modal-content bg-secondary">
//         <div class="modal-header">
//           <h6 class="modal-title">Register New {{curElementType}}</h6>
          
//         </div>
//         <div class="modal-body">

//             <div class="row  justify-content-center pt-3 pb-5">

//                 <div class="col col-lg-8 col-md-10 col-sm-11">
                        
//                     <form [formGroup]="currentElementForm" (submit)="saveElement()">
//                         <div class="form-group">

//                             <div class="row">
                                
//                                 <div class="col col-lg-12 col-md-12 col-sm-12 pt-2 pb-2" >
//                                     <label for="name">{{curElementType}} Name</label>

//                                 </div>
//                                 <div class="col col-lg-12 col-md-12 col-sm-12 pt-2 pb-2" >
                                    
//                                     <input class="form-control" type="text"
//                                         formControlName="name"
//                                         id="name">
//                                 </div>
                            
//                                 <div class="col col-lg-12 col-md-12 col-sm-12 pt-2 pb-2">
//                                     <label for="Image">Image</label>
//                                     <input class="form-control w-100" type="text"
//                                         formControlName="image"
//                                         id="image">
//                                 </div>
//                                 <div class="col col-lg-12 col-md-12 col-sm-12 pt-2 pb-2" *ngIf="isRequired('url')">
//                                     <label for="URL">URL</label>
//                                     <input class="form-control" type="text"
//                                         formControlName="url"
//                                         id="url">
//                                 </div>
//                                 <div class="col col-lg-12 col-md-12 col-sm-12 pt-2 pb-2" *ngIf="isRequired('contact')">
//                                     <label for="contact">contact</label>              
//                                     <input class="form-control" type="text"
//                                         formControlName="contact"
//                                         id="contact">
//                                 </div>
//                                 <div class="row" *ngIf="isRequired('address')">
//                                     <div class="col col-lg-12 col-md-12 col-sm-12 pt-2 pb-2">
//                                         <label for="address">address</label>    
//                                     </div>
//                                     <div class="col col-lg-12 col-md-12 col-sm-12 pt-2 pb-2" >
                                    
//                                         <div data-mdb-input-init class="form-outline">
//                                             <textarea class="form-control" formControlName="address" id="address" rows="3"></textarea>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div class="row" *ngIf="isRequired('gender')">
//                                     <div class="col col-lg-12 col-md-12 col-sm-12 pt-2 pb-2"  >
//                                         <label for="Gender">Gender</label>
//                                     </div>
//                                     <div class="col col-lg-2 col-md-3 col-sm-3 pt-2 pb-2 ps-0 pe-0" *ngFor="let gender of genders">
//                                         <input type="radio" class="btn-check" name="gender" id="{{gender}}" autocomplete="off" 
//                                         [checked]="(gender==selectedGender)" (click)="setSelectedGender(gender)">
//                                             <label class="btn btn-outline-primary" for="{{gender}}">{{gender}}</label>                   
//                                     </div>
//                                 </div>
//                                 <div class="col col-lg-12 col-md-12 col-sm-12 pt-2 pb-2" >
//                                     <label for="allTags">allTags</label>
//                                 </div>                
//                                 <div class="col col-lg-12 col-md-12 col-sm-12 pt-2 pb-2" >
//                                     <div class="row">
//                                         <div class="col pt-1 pb-1 ps-1 pe-1" *ngFor="let tag of currentElementForm.get('allTags')?.value;let i=index">
//                                             <input type="checkbox"  (click)="setAllTagSelected(tag)" class="btn-check" name="tag" id="{{tag}}" autocomplete="off" >
//                                             <label class="btn btn-outline-primary" for="{{tag}}">{{tag}}</label>    
//                                         </div> 
//                                     </div>
//                                 </div>
//                                 <!-- <div class="col col-lg-4 col-md-6 col-sm-12 pt-2 pb-2" >
//                                     <label for="elementTags">elementTags</label>
//                                     <input class="form-control" type="text"
//                                         formControlName="elementTags"
//                                         id="elementTags">
//                                 </div> -->
//                                 <div class="col col-lg-12 col-md-12 col-sm-12 pt-2 pb-2" >
//                                     <label for="allTags">{{curElementType}}Element Tags</label>
//                                 </div>                
//                                 <div class="col col-lg-12 col-md-12 col-sm-12 " >
//                                     <div class="row">
//                                         <div class="col pt-1 pb-1 ps-1 pe-1" *ngFor="let etag of curElementTags;let i=index">
//                                             <input type="checkbox" (click)="setETagSelected(etag)" class="btn-check" name="etag" id="{{etag}}" autocomplete="off" >
//                                             <label class="btn btn-outline-primary" for="{{etag}}">{{etag}}</label>    
//                                         </div> 
//                                     </div>
//                                 </div>
//                             </div>
//                             <div class="row pt-4">
//                                     <div fromArrayName="paras">
//                                         <h4>Paragraph </h4>
                                    
//                                         <div class="form-group" *ngFor="let eachGroup of currentElementForm.get('paras')?.value;let i=index">
//                                             <!-- <input type="text" class="form-control" [formControlName]="i"> -->
                                            
//                                             <form [formGroup]="eachGroup" >
//                                                 <div class="form-group">
                                            
//                                                     <div class="row">
//                                                         <div class="row ps-4 pt-3 pb-4 justify-content-start">
//                                                             <div class="col col-lg-12 col-md-12 col-sm-12 pt-2 pb-2">
//                                                                 <label for="paraName">paraName </label>
//                                                                 <input class="form-control" type="text"
//                                                                     formControlName="paraName"
//                                                                     id="paraName">
//                                                             </div>
                                                    
//                                                             <div class="col col-lg-12 col-md-12 col-sm-12 pt-2 pb-2">
//                                                                 <label for="paraBody">paraBody</label>
//                                                                 <!-- <input class="form-control" type="text"
//                                                                     formControlName="paraBody"
//                                                                     id="paraBody"> -->
//                                                                 <div data-mdb-input-init class="form-outline">
//                                                                     <textarea class="form-control" formControlName="paraBody" id="paraBody" rows="6"></textarea>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                     <div class="row ps-4 pt-3 pb-4 justify-content-end">
//                                                         <div class="col col-lg-4 col-md-6 col-sm-12 pt-2 pb-2">
//                                                             <button class="btn btn-primary" (click)="removePara(i)" >Remove </button>
//                                                         </div>
                                                        
//                                                     </div>
//                                                 </div>
//                                             </form>
//                                         </div>
                                        
                                        
//                                 </div>
//                             </div>
                            
//                         </div>
                        
//                         <div class="row ps-4 pt-3 pb-4 justify-content-center">
//                             <div class="col col-lg-8 col-md-6 col-sm-12">
//                                 <button class="btn btn-primary" type="button" (click)="addPara()">Add Para</button>
//                             </div>
//                         </div>
//                         <!-- <button type="submit" class="btn btn-default">Submit</button> -->
//                     </form>
//                 </div>
//             </div>
//         </div>

//         <div class="modal-footer">
//           <button type="button" class="btn btn-primary" (click)="saveElement()">Action</button>
//           <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="closeModalNow()">Close</button>
//         </div>
//       </div>
//     </div>
//   </div>

