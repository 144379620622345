import { CommonModule } from '@angular/common';
import { Component, effect, inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LoginService } from '../../Services/login.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-login-form',
  imports: [FormsModule,CommonModule],
  templateUrl: './login-form.component.html',
  styleUrl: './login-form.component.scss'
})
export class LoginFormComponent   implements OnDestroy{
   
  userLoggedInStatus=false;
  myId:string="";
  static alertMsg:string="";
  changePassword:boolean=false;
  visiblePasswords:boolean=false;
  static memberTypes=new Map<string,string>();
  // selectedMemType:string;
  userEmail:string|undefined;
  userPassword:string|undefined;

  userPasswordNew:string="";
  userPasswordConfirm:string="";

  loginService:LoginService = inject(LoginService);
  constructor(){

    
    
    const myModal=document.getElementById('LoginModelForm');
    // //alert("FormLoginModal  C Default Mem Type "+this.selectedMemType);
    if(myModal!=null){
      myModal.style.display='none';}
    
    
    this.userLoggedInStatus=this.loginService.getLoginStatus();

    effect(()=>{
            this.userLoggedInStatus=this.loginService.getLoginStatus();

            if(this.userLoggedInStatus){
              this.closeLoginModal();
        
              
              this.showLoggedInAlert();
              
              
            }
          });
   

  }

  showPasswords(){
    this.visiblePasswords=!this.visiblePasswords;
  }


  isPasswordMatchesActions(){
    const NPassword=document.getElementById('NPassword');
    const CPassword=document.getElementById('CPassword');
    if(NPassword!=null&&CPassword!=null){
      // alert("RAM "+NPassword.ariaValueText);
      if(this.userPasswordNew!=this.userPasswordConfirm){
        NPassword.style.color="red";
        CPassword.style.color="red";
      }else{
        NPassword.style.color="green";
        CPassword.style.color="green";
        return true;
      }
    }
    return false;
  }

  enableChangePassword(){
    if(this.changePassword){
      if(this.isPasswordMatchesActions()){
        if(this.userPasswordNew?.length<10 || this.userPasswordConfirm?.length<10){
          swal.fire("Password Length should be >=10 ");
        }
        else{

          // alert("change Password RAM");
          this.loginService.callChangePasswordApi(this.userEmail+"",this.userPassword+"",this.userPasswordNew);
        }
        
      }
      else{

        swal.fire("Password does not match");
      }
    }
    else{
      this.changePassword=true;
    }

  }

  showLoggedInAlert(){

    let alertMsg="Hi "+this.loginService.getLoginDetails().email_id+
      " Wlcome to Gurukul , Logged In time "+new Date(this.loginService.getLoggedInTime()*1000);
  
    if(LoginFormComponent.alertMsg!=alertMsg){
      LoginFormComponent.alertMsg=alertMsg;
      swal.fire("Log in Success",alertMsg,"success");
     
    }
   
  }



  idChange(emailId:string){
    this.userEmail=emailId;
  }





  passwordChangeNew(password:string){
    this.userPasswordNew=password;
    this.isPasswordMatchesActions();
    
  }

  passwordChangeConfirm(password:string){
    this.userPasswordConfirm=password;
    this.isPasswordMatchesActions();
  }

  passwordChange(password:string){
    this.userPassword=password;
  }


  onLoginClicked(){
  
    if(this.userEmail==undefined||this.userEmail.length<=5){
      alert("Please select correct Email Address "+this.userEmail)
      return;
    }
    if(this.userPassword==undefined||this.userPassword.length<10){
      alert("Please select correct Password "+this.userPassword)
      return;
    }
   
   this.loginService.tryToLogin(this.userEmail,this.userPassword);
    // this.loginService.tryToLogin("RAM","SITA");
  }


  closeLoginModal(){
    const myModal=document.getElementById('LoginModelForm');

    if(myModal!=null){
      myModal.style.display='none';
      this.doDisposeActions();
    }
  }

  openLoginModal(){
    
    const myModal=document.getElementById('LoginModelForm');
    if(myModal!=null){
      myModal.style.display='block';
    }
  }

doDisposeActions(){
  this.changePassword=false;
  this.visiblePasswords=false;
 //alert("Page destroyed RAMA");
}

 ngOnDestroy(): void {
     this.doDisposeActions();
 }
  
}


