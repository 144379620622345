import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, effect, HostListener, inject, Input } from '@angular/core';
import { LoginService } from '../../Services/login.service';
import { FilterDynamicSearchService } from '../../Services/filter-dynamic-search.service';

import { SelectedElementService } from '../../Services/selected-element.service';
import { AdminViewService } from '../../Services/admin-view.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TextAreaElement } from '../../Models/Interfaces/textAreaElement';
import { PandityaFilterComponent } from '../panditya-filter/panditya-filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { YouTubePlayerModule } from '@angular/youtube-player';

@Component({
  selector: 'app-gen-one-list-view',
  imports: [CommonModule,PandityaFilterComponent,ReactiveFormsModule,FormsModule,YouTubePlayerModule],
  
  templateUrl: './gen-one-list-view.component.html',
  styleUrl: './gen-one-list-view.component.scss'
})
export class GenOneListViewComponent implements AfterViewInit{
  _baseLog:string="GenOneListViewComponent\t";
  _listFirstTitleShowText:string="Institute";
  _listSeconfTitleShowText:string='"Swaraj is my birthright, and I shall have it" - Bal Gangadhar Tilak';
  cuUrl:string="https://m.media-amazon.com/images/S/pv-target-images/acf9b9510a723d3590799c6ed29afe449dba0947ee6e8fb2fab99f9e63b1ca3f._SX1080_FMjpg_.jpg";
  past_myURL="";
  isLoggedIn=false;
  currentElementList:TextAreaElement[]=[];
  _isVideoEnabled:boolean=false;
    @Input() 
    set listTitle(value: string) 
    {
      ////alert(this._baseLog+"List-Title change request new "+value);
      this._listTitle = value; 
      this.takeAdminActions();
      this._listFirstTitleShowText=this._listTitle;
      
      this.updateListSeconfTitleShowText();
      //alert("RAM JI BOLIE");
      // this.adminLoggedInAction();
      //  this.doOnChanges();
    }
    get listTitle():string {
      return this._listTitle; 
    }
    _listTitle:string="List-Unknown";

    @Input()
    set fromHome(value: string) {
      // //////////alert(this._baseLog+"fromHome Set - "+value);  
      this._fromHome=value;
     
    }
    get fromHome():string {      
      return this._fromHome; 
    }
    _fromHome:string="false";

  
      set myURL(value: string) {
        //////////alert(this._baseLog+"MY-URL Set - "+value);
        if(value!=null&&value!=undefined&&value.length>6){  //&&value!=this._myURL
          this._myURL = value;          
          this.doOnChanges();
        }
        // this._myURL = value;    
     
      }
      get myURL():string {
        
        return this._myURL; 
      }
      private _myURL:string="";
      
    cardHeight: number=150  ;
    cardWidth: number=150 ;
    windowHeight: number=150  ;
    windowWidth: number=150 ;
    setWindowSize(){
      this.windowWidth=window.innerWidth;
      this.windowHeight=window.innerHeight;
      this.cardHeight=this.windowHeight/3;
      if(this.windowWidth>800)
        {
          this.cardWidth=this.windowWidth*0.29;
   
        }
      else if(this.windowWidth>600)
        {
          this.cardWidth=this.windowWidth*0.55;
         
        }
      else 
          {
            this.cardWidth=this.windowWidth*0.8;
            
          }
     
     }
     videoClick(){
      // alert("Video Clicked ");
     }
  
      @HostListener('window:resize', ['$event'])
      getScreenSize() {
          
          
          this.setWindowSize();
   
         
      }
      public ngAfterViewInit() {
        this.setWindowSize();
     
    }
      http=inject(HttpClient);
      //! dynamicSearchService:FilterDynamicSearchService=new FilterDynamicSearchService();

      
       private loginService=inject(LoginService);

      constructor( 
      private  dynamicSearchService:FilterDynamicSearchService,
                    private adminViewService:AdminViewService,
                    private elementUpdateService:SelectedElementService,
                    // private router:Router,
                     
      ){
            this.myURL=this.dynamicSearchService.getCurrentElementFilterUrl();
            this.isLoggedIn=this.loginService.getLoginStatus();
            // !this.isLoggedIn=(this.loginService.loginResponse!=undefined)?this.loginService.loginResponse?.isLoggedIn:false;
            effect(()=>{
                if(!this.isLoggedIn){                  
                  this.myURL=this.dynamicSearchService.getCurrentElementFilterUrl();   
                }
              }              
            );
            effect(()=>{
            
              this.myURL=this.adminViewService.getCurrentAdminURL();
              //  alert("List Recv new Delete change req "+ this.myURL);
            }

            );       
            effect(()=>{
              
              this.isLoggedIn=this.loginService.getLoginStatus();
              this.takeAdminActions(); 
              }    
            );     
              // this.adminLoggedInAction();
      }

      canIshowVideo(){
        return this._isVideoEnabled;
      }
      isHomePage(){
        return (this.fromHome=="inHome")?true:false;
      }
    
      updateListSeconfTitleShowText(){
        this._isVideoEnabled=false;
        if(this.listTitle.toLowerCase()=="institute"){
          this._listFirstTitleShowText="Institutions";
          this._listSeconfTitleShowText="Empowering Your Institutional Journey";
        }
        if(this.listTitle.toLowerCase()=="scholarship"){
          this._listFirstTitleShowText="Scholarships";
          this._listSeconfTitleShowText="Scholarships at Your Fingertips";
        }
        if(this.listTitle.toLowerCase()=="edutube"){
          this._isVideoEnabled=true;
          // alert("Can show Video Tab ");
          this._listFirstTitleShowText="Edutube";
          this._listSeconfTitleShowText="Transfer Your Future with the Right Content";
        }
        if(this.listTitle.toLowerCase()=="teacher"){
          this._listFirstTitleShowText="Teachers";
          this._listSeconfTitleShowText="Your Video Guide to Knowledge";
        }
        if(this.listTitle.toLowerCase()=="course"){
          this._listFirstTitleShowText="Courses";
          this._listSeconfTitleShowText="Guiding Lights for Brighter Futures";
        }
      }

  loadMoreElements(){

    if(this.isLoggedIn){ 
      // alert("Started ID Change A");
      try{ this.doAdminDataLoading(this.currentElementList[this.currentElementList.length-1].id+"");}catch(e){}
     
    }else{
      // alert("Started ID Change");
      try{ this.doUserDataLoading(this.currentElementList[this.currentElementList.length-1].id+"");}catch(e){}
    }
  
  }

  takeAdminActions(){
    if(this.isLoggedIn){
      this.adminViewService.setElementType(this._listTitle);
    }
    else{
      this.myURL=this.dynamicSearchService.getCurrentElementFilterUrl();  
     
    }
    this.doOnChanges();
  }
  
  
  doOnChanges(){
    
    if(this,this.isLoggedIn){ 
      this.doAdminDataLoading(undefined);
    }else{
      this.doUserDataLoading(undefined);
    }
      
  }

   getUrlId(url:string){
        // alert(" Video ID >>> "+  (url.split("=")[1]).split("&")[0]);
        return (url.split("=")[1]).split("&")[0];
      }
 

  doYoutubeIDMapping(response:TextAreaElement[]){
    this.currentElementList=[];
    
    for(let i=0;i<response.length;i++){
      let obj:TextAreaElement=response[i];
      if(this.listTitle.toLowerCase()=="edutube"&&obj.image!=undefined){
        
        obj.image=this.getUrlId(obj.image);
        // alert("Pushing Element "+obj.image);
      }
      this.currentElementList.push(obj);
    }
    // alert("Pushing Element Complete "+this.currentElementList.length);
  }

  setSelectedElement(curElement:TextAreaElement){
    this.elementUpdateService.updateSelectedItemData(curElement);
  }
    
  doUserDataLoading(lastId:string|undefined){
      // alert(this._baseLog+"doUserDataLoading API CALL START" );
      if(this.myURL!=undefined){  //&&this.myURL!=this.past_myURL
        // alert(this._baseLog+"doUserDataLoading API CALL Verified");
        this.past_myURL=this.myURL;
        this.http.get((lastId!=undefined)?""+this.myURL+"?id="+lastId:this.myURL).subscribe((response:any)=>{
        // this.currentElementList=response;
        this.doYoutubeIDMapping(response);
        this.setSelectedElement(this.currentElementList[0]);
        //alert(this._baseLog+"doUserDataLoading RAMA Got Normal Response "+JSON.stringify(response));
        
        
      // if(this.fromHome!="yes"){

      //   //! this.elementUpdateService.updateSelectedItemData(this.currentElementList[0]);
      // }

      });
      }
  }
  getAuthToken(){
    return this.loginService?.loginResponse?.access_token ;
  }
  
    doAdminDataLoading(lastId:string|undefined){
  
      try{
        //  alert(this._baseLog+" doAdminDataLoading Start  "+this.myURL);
             if(this.myURL!=undefined&&this.myURL.length>5){              //&&this.myURL!=this.past_myURL
                var reqHeader = new HttpHeaders({               
                  'Authorization': 'Bearer ' + this.getAuthToken()
               });
              //  alert(this._baseLog+" doAdminDataLoading Start  "+this.myURL);
               this.past_myURL=this.myURL;
               this.http.get((lastId!=undefined)?""+this.myURL+"?id="+lastId:this.myURL, { headers: reqHeader })
                  
                .subscribe(
                (response:any) => {            

                  // this.currentElementList=response;
                  this.doYoutubeIDMapping(response);
                  if(this.currentElementList.length>0){
                    this.setSelectedElement(this.currentElementList[0]);
                  }else{
                    this.setSelectedElement(new TextAreaElement());
                  }
                 
                },
                (error) => {  
                      alert(this._baseLog+" Admins doAdminDataLoading  APIerror "+this.myURL+" -- Error "+JSON.stringify(error));
                    }
                );    
                }
          }catch(e){
            alert(this._baseLog+"Admins -doAdminDataLoading Trying API CALL ERROR  "+this.myURL+" -- Error "+JSON.stringify(e));
          }
  
     
    }
}   

