import { CommonModule } from '@angular/common';
import { Component, effect, ElementRef, Input, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ElementETagService } from '../../Services/element-etag.service';
import { ElementTagService } from '../../Services/element-tag.service';
import { TextAreaElement } from '../../Models/Interfaces/textAreaElement';
import { APICallerGenOneService } from '../../Services/apicaller-gen-one..service';
import { SelectedElementService } from '../../Services/selected-element.service';




@Component({
  selector: 'app-element-form',
  imports: [FormsModule,CommonModule,ReactiveFormsModule],
  templateUrl: './element-form.component.html',
  styleUrl: './element-form.component.scss'
})
export class ElementFormComponent {

  //! Starting New
  _curEditMode="create"
  formNameRegex:RegExp=new RegExp( ".{3,50}");
  _baseLog="ElementFormComponent ";

  print(text:string){
    alert(this._baseLog+text);
  }
  formCanBeSubmitted=false;
  _curApproveCount="";
  _curNotApproveCount="";

  _paraNames:string[]=[];
  _paraBodys:string[]=[];
  
  paragraphForm:FormGroup=this.getinitialGroup();
  curSelectedElement:TextAreaElement=new TextAreaElement();

  paraTitles:string[]=[];
  paraBodys:string[]=[];
  curImage:string="https://www.drishtiias.com/images/blogs/Celebrating-the-Legacy-of-a-Warrior-Subhash-Chandra-Bose.png";
  allTags:string[]=["EXA1","EXA2","EXA3","EXA4"];
  curElementTags:string[]=["EXA1","EXA2","EXA3","EXA4"];
  selectedAllTags:string[]=[];
  selectedElementTags:string[]=[];


  // @ViewChild("paraBody") firstChild:ElementRef ;


  genders:string[]=["FEMALE","MALE","OTHERS"];
  
  selectedGender="FEMALE";

  curControls=this.getInitialControls();

  getInitialControls(){
    return ["name","image","allTags","paras"];
  }
   @Input() 
      set curElementType(value: string) 
      {
        this._curElementType = value; 
        this.resetForm();
        // this.curControls=this.getInitialControls();    
        // this.getElementProperties();
        // alert("Done change for "+this._curElementType);
      }
      get curElementType():string {
        return this._curElementType; 
      }
      _curElementType:string="Institute";


  //! Finish
  genOneApiCaller:APICallerGenOneService=APICallerGenOneService.getInstance();


  constructor(
    private inHouseETagService:ElementETagService,
      private inHouseTagService:ElementTagService,
      public selectedElementService:SelectedElementService
  ){
    // this.curSelectedElement=selectedElementService.getSelectedItem();
    try{
      this.closeModalNow();
      this.curElementTags=this.inHouseETagService.getCurrentElementTag();
      // this.curElementType="teacher";
      
      
      effect(()=>{
        this.allTags=this.inHouseTagService.getAllTag();
      });
      effect(()=>{
        //
          this.curElementTags=this.inHouseETagService.getCurrentElementTag();
          
          // this.print("All Ele Tags "+this.curElementTags);
      });
    }catch(e:any){
      this.print("constructor "+e);
    }
  }

  isSelectedGender(gender:string){

    return (gender==this.selectedGender);
  }
  
  isEtagSelected(etag:string){
    return this.selectedElementTags.includes(etag);
  }

  isAtagSelected(tag:string){
    return this.selectedAllTags.includes(tag);
  }
  
  //! New Implementation



  getadminUrlPrefix(){
    return (sessionStorage.getItem("adminelemgmt")!=null)?sessionStorage.getItem("adminelemgmt"):"URLNOTFOUND adminelemgmt";
  }

  submitAction(){
    
    // this.print(" submitAction Obj -- "+JSON.stringify(this.getFormData()));  
    this.genOneApiCaller.postElementData("Posting Element "+(<HTMLInputElement>document.getElementById("name")).value,this.getadminUrlPrefix()+"/"+this.curElementType.toLowerCase(),this.getFormData());
    
  }

  checkMyName(key:string){
    // this.print("Regex check "+this.formNameRegex.test((<HTMLInputElement>document.getElementById(key)).value));
    if(!this.formNameRegex.test((<HTMLInputElement>document.getElementById(key)).value)){
      (<HTMLInputElement>document.getElementById(key)).style.color="red";
      this.formCanBeSubmitted=false;
    }
    else{
      (<HTMLInputElement>document.getElementById(key)).style.color="black";
      this.formCanBeSubmitted=true;
    }

    
  }

  changeImage(){
    this.curImage=(<HTMLInputElement>document.getElementById("image")).value;
  }

  
  closeModalNow(){
    this.resetForm();
    const myModal=document.getElementById('elementCreateUpdateOne');
    if(myModal!=null){
      myModal.style.display='none';
    }
  }

 
  getDataFromCurObj(){
    // this.getElementProperties();
    // this.print("CurControls "+this.curControls+" __ "+this.curElementType+">>");
    for(let i=0;i<this.curControls.length;i++){
      let key=this.curControls[i];
      

      if(this.curControls[i]!="allTags"
        &&this.curControls[i]!="elementTags"
        ){
          // alert("Key Check  "+i+" ++ "+key+" Check "+(key=="gender"));
          if(key=="name"){
            (<HTMLInputElement>document.getElementById(key)).value=this.curSelectedElement.name+"";
          }
          else if(key=="image"){
            (<HTMLInputElement>document.getElementById(key)).value=this.curSelectedElement.image+"";
            this.curImage=this.curSelectedElement.image+"";
          }
          else if(key=="url"){
            (<HTMLInputElement>document.getElementById(key)).value=this.curSelectedElement.url+"";
          }
          else if(key=="address"){
            (<HTMLInputElement>document.getElementById(key)).value=this.curSelectedElement.address+" G"+(this.curSelectedElement.gender+"").toUpperCase();
          }
          else if(key=="contact"){
            (<HTMLInputElement>document.getElementById(key)).value=this.curSelectedElement.contact+"";
          }   
          else if(key=="paras"){
            
            this._paraBodys=(this.curSelectedElement.parabody!=undefined)?this.curSelectedElement.parabody:[];
            this._paraNames=(this.curSelectedElement.paraname!=undefined)?this.curSelectedElement.paraname:[];
            // this.print(" Para Start "+JSON.stringify(this._paraNames));
          }
          // else if(key=="gender"){
          //   // alert("Gender Data Updated "+this.selectedGender);
          //   // this.selectedGender=;;
          //   this.setSelectedGender((this.curSelectedElement.gender!=undefined)?this.curSelectedElement.gender:"FEMALE");
          //    alert("Gender Data Updated "+this.selectedGender);
          // }
      }
    }
  }

  setUpdateMode(){
    this._curEditMode="update";
    this.curSelectedElement=this.selectedElementService.getSelectedItem();
    this.getDataFromCurObj();
    // alert("Update Event "+JSON.stringify(this.curSelectedElement));
  }

  openModalNow(status:string,thisElementType:string){
    
   this.curElementType=thisElementType;
 
    // this.resetForm();
    // alert("Creation Form Called With status "+status);
    const myModal=document.getElementById('elementCreateUpdateOne');

    if(myModal!=null){
      myModal.style.display='block';
      // if(status="update"){
        
      // this.setUpdateMode();
      // }else{
      //   this._curEditMode="create";
      //   this.curSelectedElement=new TextAreaElement();
      //   this.getDataFromCurObj();
      // }
    }
  }
  
  checkAllControls(){
    let finalStatus:boolean=true;
    if(this.selectedAllTags.length<=0){
      (<HTMLInputElement>document.getElementById("tagBox")).style.color="red";
      (<HTMLInputElement>document.getElementById("tagBox")).style.borderColor="red";
      finalStatus=false;
    }
    else{
      (<HTMLInputElement>document.getElementById("tagBox")).style.borderColor="green";
      (<HTMLInputElement>document.getElementById("tagBox")).style.color="black";
    }
    if(this.selectedElementTags.length<=0){
      (<HTMLInputElement>document.getElementById("etagBox")).style.color="red";
      (<HTMLInputElement>document.getElementById("etagBox")).style.borderColor="red";
      finalStatus=false;
    }
    else{
      (<HTMLInputElement>document.getElementById("etagBox")).style.borderColor="green";
      (<HTMLInputElement>document.getElementById("etagBox")).style.color="black";
    }
    for(let i=0;i<this.curControls.length;i++){
      let key=this.curControls[i];
      if(this.curControls[i]!="paras"&&this.curControls[i]!="allTags"&&this.curControls[i]!="elementTags"&&this.curControls[i]!="gender"){
        
        if(key=="name"&&!this.formNameRegex.test((<HTMLInputElement>document.getElementById(key)).value)){
          (<HTMLInputElement>document.getElementById(key)).style.color="red";
          (<HTMLInputElement>document.getElementById(key)).style.borderColor="red";
          finalStatus=false;
        }
    
        else if(key=="image"&&!this.formNameRegex.test((<HTMLInputElement>document.getElementById(key)).value)){
          (<HTMLInputElement>document.getElementById(key)).style.color="red";
          (<HTMLInputElement>document.getElementById(key)).style.borderColor="red";
          finalStatus=false;
        }
        else if(key=="url"&&!this.formNameRegex.test((<HTMLInputElement>document.getElementById(key)).value)){
          (<HTMLInputElement>document.getElementById(key)).style.color="red";
          (<HTMLInputElement>document.getElementById(key)).style.borderColor="red";
          finalStatus=false;
        }
        else if(key=="address"&&!this.formNameRegex.test((<HTMLInputElement>document.getElementById(key)).value)){
          (<HTMLInputElement>document.getElementById(key)).style.color="red";
          (<HTMLInputElement>document.getElementById(key)).style.borderColor="red";
          finalStatus=false;
        }
        else if(key=="contact"&&!this.formNameRegex.test((<HTMLInputElement>document.getElementById(key)).value)){
          (<HTMLInputElement>document.getElementById(key)).style.color="red";
          (<HTMLInputElement>document.getElementById(key)).style.borderColor="red";
          finalStatus=false;
        }   
        else{
          (<HTMLInputElement>document.getElementById(key)).style.borderColor="green";
          (<HTMLInputElement>document.getElementById(key)).style.color="black";
        }
      }
    }
    return finalStatus;
  }

  checkMyStatus(key:string){

    let finalStatus:boolean=true;
    if(key=="name"&&!this.formNameRegex.test((<HTMLInputElement>document.getElementById(key)).value)){
      (<HTMLInputElement>document.getElementById(key)).style.color="red";
      (<HTMLInputElement>document.getElementById(key)).style.borderColor="red";
      finalStatus=false;
    }

    else if(key=="image"&&!this.formNameRegex.test((<HTMLInputElement>document.getElementById(key)).value)){
      (<HTMLInputElement>document.getElementById(key)).style.color="red";
      (<HTMLInputElement>document.getElementById(key)).style.borderColor="red";
      finalStatus=false;
    }
    else if(key=="url"&&!this.formNameRegex.test((<HTMLInputElement>document.getElementById(key)).value)){
      (<HTMLInputElement>document.getElementById(key)).style.color="red";
      (<HTMLInputElement>document.getElementById(key)).style.borderColor="red";
      finalStatus=false;
    }
    else if(key=="address"&&!this.formNameRegex.test((<HTMLInputElement>document.getElementById(key)).value)){
      (<HTMLInputElement>document.getElementById(key)).style.color="red";
      (<HTMLInputElement>document.getElementById(key)).style.borderColor="red";
      finalStatus=false;
    }
    else if(key=="contact"&&!this.formNameRegex.test((<HTMLInputElement>document.getElementById(key)).value)){
      (<HTMLInputElement>document.getElementById(key)).style.color="red";
      (<HTMLInputElement>document.getElementById(key)).style.borderColor="red";
      finalStatus=false;
    }   
    else{
      (<HTMLInputElement>document.getElementById(key)).style.borderColor="green";
      (<HTMLInputElement>document.getElementById(key)).style.color="black";
      finalStatus=false;
    }
    this.formCanBeSubmitted=this.checkAllControls();
  }

  cleanControls(){
    
    for(let i=0;i<this.curControls.length;i++){
      if(this.curControls[i]!="paras"&&this.curControls[i]!="allTags"&&this.curControls[i]!="elementTags"&&this.curControls[i]!="gender"){
        
        try{
          (<HTMLInputElement>document.getElementById(this.curControls[i])).value="";
        }catch(e){
          // this.print(" cleanControls Error "+this.curControls[i]+" >> "+e);
        }
       
      }
     
    }
  }

  resetForm(){
    this.formCanBeSubmitted=false;
    this.cleanControls();
    this.doParaClean();
    this.selectedAllTags=[];
    this.selectedElementTags=[];
    this.curControls=this.getInitialControls();    
    this.getElementProperties();
  }

  doParaClean(){
    this._paraNames=[];
    this._paraBodys=[];    
    this.paragraphForm=this.getinitialGroup();
  
  }


  isControlRequired(controlName:string){
    // this.print(" Cur Element Control Check "+controlName+" Status "+this.curControls.indexOf(controlName));
    return (this.curControls.indexOf(controlName)>-1) ?true:false;
    
    }
  
  

  setSelectedGender(gender:string){
    this.selectedGender=gender;
  }

  updateParagraphMap(){
    this.paraTitles.push("RAMSITARAM");
    this.paraTitles.push("RAMSITARAM");
    this.paraTitles.push("RAM","RAMSITARAM");
  }


  removeParaPossible(){
    return (this.paraTitles.length>0)?true:false;
  }

  addParaPossible(){
    return (this.paraTitles.length<3)?true:false;
  }

  getCurrentParasKeys(){
    return  this.paraTitles; // [ "",""];
  }


  getElementProperties(){
    // alert("getElementProperties >> "+this.curElementType)
    this.curControls.push("elementTags");
    if(this.curElementType.toLowerCase()=="institute"){
      this.addInstituteControls();      
      return;
    }else if(this.curElementType.toLowerCase()=="edutube"){
      this.addEdutubeControls();
      return;
    }
    else if(this.curElementType.toLowerCase()=="teacher"){
      this.addTeacherControls();
      return;
    }
    else if(this.curElementType.toLowerCase()=="scholarship"||this.curElementType.toLowerCase()=="course"){
      this.addCourseScholarshipControls();     
      return;
    }
    
  }

  addInstituteControls(){
    this.curControls.push("address");
    this.curControls.push("contact");
    this.curControls.push("url");
    // alert("After Institute Add "+this.curControls)
  }

  addCourseScholarshipControls(){
    this.curControls.push("address");
    this.curControls.push("url");

  }

  addTeacherControls(){
    this.curControls.push("address");
    this.curControls.push("contact");
    this.curControls.push("gender");

  }

  addEdutubeControls(){
    this.curControls.push("url");
  }

  setAllTagSelected(tagName:string){
    
    if(this.selectedAllTags.indexOf(tagName)>=0){
      this.selectedAllTags=this.selectedAllTags.filter(tag=>tag!=tagName);
    }
    else{
      this.selectedAllTags.push(tagName);
    }
    this.formCanBeSubmitted=this.checkAllControls();
  }

  setETagSelected(tagName:string){
    if(this.selectedElementTags.indexOf(tagName)>=0){
      this.selectedElementTags=this.selectedElementTags.filter(tag=>tag!=tagName);
    }
    else{
      this.selectedElementTags.push(tagName);
    }
    this.formCanBeSubmitted=this.checkAllControls();
  }

  // //! End of New


  setCurElementObjData(propName:string,curObj:TextAreaElement,value:string){
    if(propName=="name"){
      curObj.name=value;
      return;
    }
    if(propName=="address"){
      curObj.address=value;
      return;
    }
    if(propName=="contact"){
      curObj.contact=value;
      return;
    }
    if(propName=="image"){
      curObj.image=value;
      return;
    }
    if(propName=="url"){
      curObj.url=value;
      return;
    }

  }

  getDocumentDataById(idKey:string):any{
    try{
      return (<HTMLInputElement>document.getElementById(idKey)).value;
    }catch(e){
      this.print(" getDocumentDataById Error Trying to find "+idKey+" >> "+e);
    }
    return "";
  }

  getElementTagsAssigned(curObj:TextAreaElement){
    if(this.curElementType.toLowerCase()=="institute"){
      curObj.institueTags=this.selectedElementTags;    
      return;
    }else if(this.curElementType.toLowerCase()=="edutube"){
      curObj.edutubeTags=this.selectedElementTags;    
      return;
    }
    else if(this.curElementType.toLowerCase()=="teacher"){
      curObj.teacherTags=this.selectedElementTags;  
      return;
    }
    else if(this.curElementType.toLowerCase()=="scholarship"){
      curObj.scholarshipTags=this.selectedElementTags;     
      return;
    }
    else if(this.curElementType.toLowerCase()=="course"){
      curObj.courseTags=this.selectedElementTags;     
      return;
    }
  }


  
  getParaAssigned(curObj:TextAreaElement){
    // this.print(" Para Check ");
    let paraN=this.getParaNamesData();
    curObj.paraname=[];
    for(let i=0;i<paraN.length;i++){
      curObj.paraname?.push(paraN[i]);
    }
    curObj.parabody=[];
    let paraB=this.getParaBodysData();
    for(let i=0;i<paraB.length;i++){
      curObj.parabody?.push(paraN[i]+"<<**RAM**>>"+paraB[i]);     
    }
    // alert("Settled Para data "+JSON.stringify(curObj.paraname)+"--"+JSON.stringify(curObj.parabody));
  }

  getFormData(){
    let curObj=new TextAreaElement();

    // let sample=["name","image"];

    for(let i=0;i<this.curControls.length;i++){
      let curControll:string=""+this.curControls.at(i);
      if(curControll=="allTags"){
        curObj.allTags=this.selectedAllTags;
      }
      else if(curControll=="elementTags"){
        this.getElementTagsAssigned(curObj);
      }
      else if(curControll=="gender"){
        curObj.gender=this.selectedGender;
      }
      else if(curControll=="paras"){
        this.getParaAssigned(curObj);
      }
      else{
        this.setCurElementObjData(curControll,curObj,this.getDocumentDataById(curControll));
      }
      
    }
    return curObj;
  }





  //!Paragraph Started 
  

 
    getinitialGroup():FormGroup
    {
      return new FormGroup({      
        paras:new FormArray([])
      });
    }

    

   

    updateParagraph(paraType:string,index:number,event: Event) {
      if(paraType=="head"){
        this._paraNames[index]=(event.target as HTMLButtonElement).value;
      }else{
        this._paraBodys[index]=(event.target as HTMLButtonElement).value;
      }

    }

    getParaNamesData(){
      return this._paraNames;
    }
    getParaBodysData(){
      return this._paraBodys;
    }

    removeParaData(index:number){
      this._paraNames=this._paraNames.filter((e, i) => i !== index);    
      this._paraBodys=this._paraBodys.filter((e, i) => i !== index);    
    }

    incrementParaBody(){
      this._paraBodys.push("");
    }
     
    incrementParaNames(){
      this._paraNames.push("");
    }


    removePara(index:number){
      this.removeParaData(index);
      (<FormArray>this.paragraphForm.get("paras")).removeAt(index);
    }

    getParaGroup():FormGroup{
      return new FormGroup({
        paraName:new FormControl("SITARAM"),
        paraBody:new FormControl("RAMAYAN"),
       
      });
    }

    getPCValue(){
      return "Ram";
    }

    getParaValuesWithType(paraType:string,index:number){
      if(paraType=="head"){
        return this._paraNames[index];
      }else{
        return this._paraBodys[index];
      }
    }

    addPara(){
      // const ncon=new FormControl(null);
      // (<FormArray>this.paragraphForm.get("paras")).push(ncon);
      this.incrementParaNames();
      this.incrementParaBody();
      let paras=this.getParaGroup();
      paras.setValue({paraName:"RAMSITA",paraBody:"RAMAYAN"});
      (<FormArray>this.paragraphForm.get("paras")).push(paras);
    }

    

    changeManagement(index:number,input:any){
      // alert("RAMJAYSTARAM "+index+" Passed ID ");
      this.paragraphForm.controls["paras"].updateValueAndValidity()
      // alert("RAMJAYSTARAM Checking "+JSON.stringify(this.paragraphForm.controls["paras"]));
    }
  


  //!Para End

}



