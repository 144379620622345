import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';

@Injectable()
export class ElementETagService {

    http=inject(HttpClient);
  
    
 
    private _currentElementTag=signal([]);

    constructor(){}
   
    isEmpty(data:string|undefined):boolean{
      return data==undefined;
    }

    getCurrentElementTag():any{
      // this.setElementTags(elementType);
    
      return this._currentElementTag();
    }

  

  setElementTags(elementType:string){
    // for(const each of this._currentElementTag() ){
    //   
    // }
   
    
    ////alert("ETagService  "+elementType)
      if(sessionStorage.getItem(elementType+"Tags")==null){
        let curUrl:string=sessionStorage.getItem("userViewUrlPref")+"/alltags/"+elementType.toLowerCase();
        this.callApi(curUrl);
        
      
    }
  }
    
  callApi(url:string):any{
   
    
      this.http.get(url).subscribe((res:any)=>{
       //
       this._currentElementTag.set(res);
       //alert("ETagServiceFilter - "+url+" -- "+this._currentElementTag());
      });
  
  }

}
