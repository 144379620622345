import { Injectable, signal } from '@angular/core';
import { ElementSuper } from '../Models/Interfaces/elementSuper';
import { TextAreaElement } from '../Models/Interfaces/textAreaElement';

@Injectable()
export class SelectedElementService {
  

  private _selectedElement=signal(new TextAreaElement(undefined,"","","",undefined,undefined,"","","",[],undefined,"","","","","",""));

 
   getSelectedItem():TextAreaElement{
    return this._selectedElement();
   }

   updateSelectedItemData(newElement:TextAreaElement){

    if(newElement.id!=this._selectedElement().id){ 
      this._selectedElement.set(newElement);
      // alert("RAM Ji Element Updated From Serviuce-Update "+this._selectedElement().allTags);
    }
  }
    
}
