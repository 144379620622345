

<div class="modal col-lg-10 col-md-10 col-sm-10" id="pageOneDiisplay" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg " role="document">
        <div class="modal-content bg-secondary"> 
            <div class="modal-header bg-primary shadow-lg">
                <div class=" row pt-1 justify-content-start align-items-start">
                    <h3 class="pb-2 text-secondary text-center"> {{getTitle()}}</h3> 
                </div>
                
                
                
            </div>
            <div class=" row pt-1 pb-1 ps-3 pe-3 justify-content-start align-items-start">                    
                <p class="pb-2 text-primary text-end">Last Updated On {{getCreationTime()}}</p>   
            </div>

            <div class="row justify-content-center align-items-center ">
                <div class="col col-12">
                <div class="card rounded-3 bg-secondary">
                  
                    <div class="card-body p-4">
                    <div class=" row  ps-1 bg-secondary rounded" *ngFor="let recipient of getParagraps()">
                        <div class="row m-2">
                            <div class="col-12 ">
                                <p class="text-primary text-start"> {{recipient}}</p>
                            </div>
                        
                            
                        </div>
                       
                    </div>
                    
                    <!-- <div class=" row  ps-1 bg-secondary rounded" *ngFor="let recipient of getParagraps()">
                        <div class="row ps-2 pe-1 shadow-lg ">
                            <p class="text-primary">{{recipient}}</p>
                            
                        </div>
                        <div class="row pt-1 pb-1"></div>
                    </div> -->
                    
                   
                    <div class="row pt-5 pb-2 justify-content-end ">
                            <div class="col-4 ">
                                <button type="button" class="btn btn-outline-info shadow-lg bg-secondary"
                                (click)="closeModalNow()" >
                                    Close
                                </button> 
                            </div>
                    </div> 
                   
                       
                       
                    </div>
                </div>
                </div>
            </div>



    </div>

    
</div>
</div>


<!-- 
<div class="col-sm-12 col-lg-3 col-md-3 ">
    <p class="text-primary"> {{recipient.key}}</p>
</div>
<div class="col-sm-12 col-lg-9 col-md-9 " >
    <div class="row" *ngFor="let parab of getParagrapBreakings(recipient.value)">
        <div class="col-sm-12 col-lg-3 col-md-3 "></div>
        <p >{{parab}}</p>
    </div>
    
</div> -->