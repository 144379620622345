import { Address,URL } from '../AdminEntitys/Interfaces';


export class TextAreaElement {

     id?:string;
     name?:string;
     image?:string;
     address?:string;
     paraname?:Array<string>;
     parabody?:Array<string>;
     url?:string;
     contact?:string;
     gender?:string;
     elementTags?:string[];
     allTags?:string[];
     deleted?:any;
     created_on?:string;
     last_updated?:string;
     approvedByUser?:any;
     createdByUser?:any;
     searchCount?:any;

    institueTags?:string[];
    courseTags?:string[];
    scholarshipTags?:string[];
    teacherTags?:string[];
    edutubeTags?:string[];


    constructor(
     id?:string,
     name?:string,
     image?:string,
     address?:string,
     paraname?:Array<string>,
     parabody?:Array<string>,
     url?:string,
     contact?:string,
     gender?:string,
     elementTags?:[],
     allTags?:[],
     deleted?:any,
     created_on?:string,
     last_updated?:string,
     approvedByUser?:any,
     createdByUser?:any,
     searchCount?:any
       ){
          this.id=id;
          this.name=name;
          this.image=image;
          this.elementTags=elementTags;
          this.gender=gender;
          this.contact=contact;
          this.paraname=paraname;
          this.parabody=parabody;
          this.url=url;
          this.address=address;

          this.allTags=allTags;
          this.deleted=deleted;
          this.created_on=created_on;
          this.last_updated=last_updated;
          this.approvedByUser=approvedByUser;
          this.createdByUser=createdByUser;
          this.searchCount=searchCount;
        } 

}


  