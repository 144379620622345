

<div class="container-fluid  shadow-md text-hovergstyles p-0"
[style.height.px]="cardHeight"
				[style.width.px]="cardWidth-10">

  <!-- <div class="rowshadow-lg  bg-white rounded "
  [style.height.px]="cardHeight"
				[style.width.px]="cardWidth"> -->
    
        <youtube-player
        *ngIf="canIshowVideo()"
        [width]="cardWidth-20" 
        [height]="cardHeight"
        videoId={{myURL}}
        placeholderImageQuality="high"
        ></youtube-player>

        <img src={{myURL}}
        *ngIf="!canIshowVideo()"
        [style.height.px]="cardHeight"
        [style.width.px]="cardWidth-20"
        class="img shadow-lg  rounded-3" alt="Responsive Image">
  <!-- </div> -->
</div>
<div class="row pt-3 pb-3"></div>