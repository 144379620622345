
<header>
    <nav class="navbar navbar-expand-md bg-primary navbar-right p-3">
            <div class="container-fluid bg-secondary rounded-2 gurukul-font-primary-style p-1 shadow-lg " >
        
                    <a class="navbar-brand ps-2 pe-2 text-primary text-hovergstylep" href="">InGurukul</a>
                    <!-- <img alt="My image name" src="/assets/ingurukullogo.png" class="PageIcon" > -->

                    <!-- <div class=>
                        <i class="bi bi-facebook pe-2 text-primary "></i>
                        <i class="bi bi-whatsapp ps-2 pe-2 text-primary"></i>
                        <i class="bi bi-envelope-at-fill ps-2 pe-2 text-primary"></i>
                        <i class="bi bi-telephone-outbound-fill ps-2 pe-2 text-primary" ></i>
                    </div>                     -->

                    <button class="navbar-toggler navbar-tertiary text-primary" 
                    data-bs-toggle="collapse" 
                    data-bs-target="#main-menu">
                            <span class="bi bi-three-dots text-primary" ></span>
                    </button>
                    
                    <div id="main-menu"
                    
                     class="collapse navbar-collapse  justify-content-end ">
                            <ul class="navbar-nav  pe-5 ">
                                    <li class="nav-item ">
                                            <a class="nav-link  text-center text-primary head-text-hovergstylep" [routerLink]="['/home']" >Home</a>
                                    </li>

                                    <li class="nav-item " *ngFor="let element of allElements; trackBy: trackByName">
                                        <a class="nav-link text-primary text-center head-text-hovergstylep" [routerLink]="['/short-view']"  
                                         [queryParams]="{elementType:element,selectedElementId:'#123RAMA'}">{{element}}</a>
                                    </li>
                                    <li class="nav-item   bg-secondary text-primary">
                                        <a class="nav-link text-primary text-center " (click)="openLoginModal()">{{logInTitle}}</a>
                                    </li>
                            </ul>
                    </div>
                    
                </div>
      
            
     </nav>
    
</header>
<app-login-form />
<div class="container-fluid bg-primary p-1 bg-primary">
<router-outlet></router-outlet>


</div>





    <!-- style="font-size: 2vw;" -->

<!-- 
<header>
    <nav class="navbar navbar-expand-md bg-secondary navbar-right p-3">
            <div class="container-fluid gurukul-font-primary-style" >
        
                    <a class="navbar-brand shadow-lg ps-2 pe-2" href="">InGurukul</a>
                    <button class="navbar-toggler" 
                    data-bs-toggle="collapse" 
                    data-bs-target="#main-menu">
                            <span class="navbar-toggler-icon "></span>
                    </button>
                    <div id="main-menu" class="collapse navbar-collapse justify-content-end ">
                            <ul class="navbar-nav  pe-5 ">
                                    <li class="nav-item shadow ps-2 pe-2">
                                            <a class="nav-link active" routerLink="home">Home</a>
                                    </li>

                                    <li class="nav-item shadow ps-2 pe-2" *ngFor="let element of allElements; trackBy: trackByName">
                                        <a class="nav-link" routerLink="short-view"
                                         [queryParams]="{elementType:element,selectedElementId:'#123RAMA'}">{{element}}</a>
                                    </li>
                                    <li class="nav-item shadow ps-2 pe-2 bg-secondary">
                                        <a class="nav-link" (click)="openLoginModal()">{{logInTitle}}</a>
                                    </li>

                            </ul>
                    </div>
                </div>
      
            
     </nav>
    
</header> -->

<!-- 
<li class="nav-item ">
        <a class="nav-link  text-center text-primary shadow-sm text-hovergstylep"  routerLink="home">Home</a>
</li>

<li class="nav-item " *ngFor="let element of allElements; trackBy: trackByName">
    <a class="nav-link text-primary text-center shadow-sm text-hovergstylep" routerLink="short-view"
     [queryParams]="{elementType:element,selectedElementId:'#123RAMA'}">{{element}}</a>
</li>
<li class="nav-item   bg-secondary text-primary">
    <a class="nav-link text-primary text-center shadow-sm" (click)="openLoginModal()">{{logInTitle}}</a>
</li> -->