import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import {  inject, Injectable, signal } from '@angular/core';
import { LoginService } from './login.service';


import swal from 'sweetalert2';
import { PageModels } from '../Models/Interfaces/pageModels';

import  Disclaimer  from "../../assets/Disclaimer.json";
import ApplicationPolicy from "../../assets/ApplicationPolicy.json";
import  Mission  from "../../assets/Mission.json";
import Vision from "../../assets/Vision.json";
import  SupportFAQs  from "../../assets/SupportFAQs.json";
import TermsConditions from "../../assets/termsconditions.json";

export class PageGenOneService {

  static _serviceReference?:PageGenOneService;
  _curPageName=signal("");
  _curPageData=signal(new PageModels("","","",new Map<string,string>));

  http=inject(HttpClient);
  
  
  private loginService=inject(LoginService);


  constructor(){
    // alert ("Got the Data "+JSON.stringify( termsconditions));
  }

  static getInstance():PageGenOneService{
    if(PageGenOneService._serviceReference==undefined||PageGenOneService._serviceReference==null){
      PageGenOneService._serviceReference=new PageGenOneService();
    }
    return PageGenOneService._serviceReference;
  }

  getTermsConditions():any{
    
    return TermsConditions;
  }


  getMission():any{
    
    return Mission;
  }

  getVision():any{
    
    return Vision;
  }

  getSupportFAQs():any{
    
    return SupportFAQs;
  }

  getCorrectUrl(){

  }

  getCurPageName(){
    return this._curPageName();
  }
  setCurPageName(pageName:string){
    this._curPageName.set(pageName);
    if(pageName!=undefined&&pageName.length>4){
      //  alert("Page Data update"+pageName);
      this.getPageData(pageName);
    }
    
  }

  getCurPageData(){
    return this._curPageData();
  }

  getAuthToken(){
    return this.loginService?.loginResponse?.access_token ;
  }


  getPageData(pageName:string){
    if(pageName=="Disclaimer"){
      this._curPageData.set(Disclaimer);
    }
    else if(pageName=="ApplicationPolicy"){
      this._curPageData.set(ApplicationPolicy);
    }
    else if(pageName=="TermsConditions"){
      this._curPageData.set(this.getTermsConditions());
    }
    else if(pageName=="SupportFAQs"){
      this._curPageData.set(this.getSupportFAQs());
    }
    else if(pageName=="Mission"){
      this._curPageData.set(this.getMission());
    }
    else if(pageName=="Vision"){
      
      this._curPageData.set(this.getVision());
    }
    else{
      this._curPageData.set(this.getTermsConditions());
    }
  }
  
}
