import { Component,inject, OnInit } from '@angular/core';
import { ElementBodyComponent } from '../element-body/element-body.component';
import { ActivatedRoute } from '@angular/router';
import { GenOneListViewComponent } from '../gen-one-list-view/gen-one-list-view.component';
import { ElementETagService } from '../../Services/element-etag.service';
import { FilterDynamicSearchService } from '../../Services/filter-dynamic-search.service';
import { SelectedElementService } from '../../Services/selected-element.service';
import { AdminViewService } from '../../Services/admin-view.service';
//import { ,OnInit,inject,} from '@angular/core';
@Component({
  selector: 'app-element-short-view',
  imports: [ElementBodyComponent,GenOneListViewComponent],
  providers:[FilterDynamicSearchService,ElementETagService,SelectedElementService,AdminViewService],
  templateUrl: './element-short-view.component.html',
  styleUrl: './element-short-view.component.scss'
})
export class ElementShortViewComponent implements OnInit{


  _baseLog="ElementShortViewComponent ";
  fromHome:string="";
  elementType:string="Institute";
  selectedElementId:string|undefined;
  elementListUrl:string="";

  checkNull(value:any):string{
    if(value==undefined||value==null){
      return "NOFD - Session Storage - HomeBody";
    }
    else return value;
  }
  adminViewService:AdminViewService=new AdminViewService();
  activateRoute:ActivatedRoute = inject(ActivatedRoute);
   private  dynamicSearchService:FilterDynamicSearchService=inject(FilterDynamicSearchService);

  constructor(  ){
    //alert(this._baseLog+"Created new Short View By Constructor");
  }
  ngOnInit(){
  
    this.activateRoute.queryParams.subscribe(params=>{
      this.elementType=(params['elementType']!=undefined)?params['elementType']:"Institute";
      this.dynamicSearchService.setElementType(this.elementType);
      this.selectedElementId=(params['selectedElementId']!=undefined)?params['selectedElementId']:"none";
      this.fromHome=(params['fromHome']!=undefined)?params['fromHome']:"false-from short view def";
      this.elementListUrl=this.checkNull((params['elementListUrl']!=undefined)?params['elementListUrl']:sessionStorage.getItem("element"+this.elementType+"Url"));
     // //alert("Parameters RAMA Short-View"+this.elementType+"--"+this.selectedElementId+"--"+this.elementListUrl);
    })
  }

}
