<app-page-gen-one ></app-page-gen-one>
    <div class="container-fluid bg-primary">
                              
                                <div class="row  p-5">

                                                <div class="card card-block card-2 bg-secondary shadow-md rounded-1 p-1" >
                                

                                                        <div class="col col-lg-12 col-md-12 col-sm-12 p-3">
                                                                <p class="text-center text-primary text-hovergstyles">
                                                                        {{aboutUs}}
                                                                </p>
                                                        </div>                            
                                                </div>
                                                <div class="row pt-3 pb-2"></div>
                                                <!-- <div class="card card-block card-2 bg-secondary shadow-md rounded-3 p-3" >
                                                        <div class="row justify-content-center align-items-center">
                                                                     
                                                                <div class="col col-lg-2 col-md-5 col-sm-12 p-2">
                                                                        <button type="button" class="btn bg-secondary shadow-lg " (click)="openPageGenOneComponent('OurTeam')" >
                                                                                Our Team
                                                                        </button> 
                                                                             
                                                                
                                                                </div> <div class="col col-lg-2 col-md-5 col-sm-12 p-2">
                                                                        <button type="button" class="btn bg-secondary shadow-lg " (click)="openPageGenOneComponent('OurApplications')" >
                                                                                Our Applications
                                                                        </button> 
                                                                             
                                                                
                                                                </div>
                                                                <div class="col col-lg-2 col-md-5 col-sm-12 p-2">
                                                                        <button type="button" class="btn bg-secondary shadow-lg " (click)="openPageGenOneComponent('Partners')" >
                                                                                Partners
                                                                        </button> 
                                                                             
                                                                
                                                                </div>
                                                        </div>                            
                                                </div> -->
                        

                                                <!-- <div class="row pb-2"></div>
                                                <div class="card card-block card-2 bg-secondary shadow-md rounded-3 p-3" >
                                                        <div class="row justify-content-center align-items-center">
                                                                <div class="col col-lg-2 col-md-5 col-sm-12 p-2">
                                                                        <button type="button" class="btn bg-secondary shadow-lg " (click)="openPageGenOneComponent('ContactUs')" >
                                                                                Contact Us
                                                                        </button> 
                                                                             
                                                                
                                                                </div>
                                                                <div class="col col-lg-2 col-md-5 col-sm-12 p-2">
                                                                        <button type="button" class="btn bg-secondary shadow-lg " (click)="openPageGenOneComponent('Disclaimer')" >
                                                                                Disclaimer
                                                                        </button> 
                                                                
                                                                </div>
                                                                <div class="col col-lg-2 col-md-5 col-sm-6 p-2">
                                                                        <button type="button" class="btn bg-secondary shadow-lg " (click)="openPageGenOneComponent('SupportFAQs')" >
                                                                                Support & FAQs
                                                                        </button> 
                                                                               
                                                        
                                                                </div>
                                                                <div class="col col-lg-2 col-md-5 col-sm-12 p-2">
                                                                        <button type="button" class="btn bg-secondary shadow-lg " (click)="openPageGenOneComponent('Mission')" >
                                                                                Mission
                                                                        </button> 
                                                                
                                                                </div>  
                                                                <div class="col col-lg-2 col-md-5 col-sm-12 p-2">
                                                                        <button type="button" class="btn bg-secondary shadow-lg " (click)="openPageGenOneComponent('Vision')" >
                                                                                Vision
                                                                        </button> 
                                                                                
                                                                
                                                                </div>


                                                                
                                                        </div>
                                                </div> -->
                                  
                                
                                                <div class="row pb-2"></div>
                                                <div class="card card-block card-2 bg-secondary shadow-md rounded-3 p-3" >
                                                        <div class="row  justify-content-center align-items-center ">
                                                                <div class="col col-lg-2 col-md-5 col-sm-12 p-1">
                                                                        <button type="button" class="btn bg-secondary shadow-lg " (click)="openPageGenOneComponent('Disclaimer')" >
                                                                                Disclaimer
                                                                        </button> 
                                                                
                                                                </div>
                                                                <div class="col col-lg-2 col-md-5 col-sm-12  p-1">
                                                                        <button type="button" class="btn bg-secondary shadow-lg " (click)="openPageGenOneComponent('ApplicationPolicy')" >
                                                                                Application Policy
                                                                        </button> 
                                                                
                                                                </div>
                                                                <!-- <div class="col col-lg-2 col-md-5 col-sm-12">
                                                                        <button type="button" class="btn bg-secondary shadow-lg " (click)="openPageGenOneComponent('PrivacyPolicy')" >
                                                                                Privacy Policy
                                                                        </button> 
                                                                
                                                                </div> -->
                                                        </div>
                                                </div>
                                     
                                </div>
                                <div class="row  justify-content-center align-items-center  ps-4 pb-4" style="font-size: 2rem; ">
                                        
                                                <div class="col-lg-1 col-md-2 col-sm-3 ">
                                                        <i class="bi bi-facebook pe-2 text-secondary " style="font-size: 2rem; "></i>
                                                </div>
                                                <div class="col-lg-1 col-md-2 col-sm-3 ">
                                                        <i class="bi bi-whatsapp pe-2 pe-2 text-secondary" style="font-size: 2rem; "></i>
                                                </div>
                                                <div class="col-lg-1 col-md-2 col-sm-3 ">
                                                        <i class="bi bi-sm bi-envelope-at-fill pe-2 text-secondary" style="font-size: 2rem; "></i>
                                                </div>
                                                <div class="col-lg-1 col-md-2 col-sm-3 " >
                                                        <i class="bi bi-lg bi-telephone-outbound-fill pe-2  text-secondary" style="font-size: 2rem; "></i>
                                
                                                </div>                             
                                                
                                                <div class="col-lg-1 col-md-2 col-sm-3 " >
                                                <i class="bi bi-lg bi-instagram pe-2 text-secondary" style="font-size: 2rem; "></i>

                                                </div>
                                                <div class="col-lg-1 col-md-2 col-sm-3 " >
                                                <i class="bi bi-lg  bi-twitter-x pe-2 text-secondary" style="font-size: 2rem; "></i>

                                                </div>
                                                <div class="col-lg-1 col-md-2 col-sm-3 " >
                                                <i class="bi bi-lg  bi-linkedin pe-2 text-secondary" style="font-size: 2rem; "></i>

                                                </div>
                                </div>
                                <div class="row pb-4 pt-5">
                                        <div class="col pb-4 pt-5"></div>
                                </div>
            </div>
      




     <!-- Contact Us







 -->
