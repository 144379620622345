import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, HostListener, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { YouTubePlayerModule } from '@angular/youtube-player';

@Component({
  selector: 'app-my-carousel',
  imports: [YouTubePlayerModule,CommonModule,ReactiveFormsModule,FormsModule],
  templateUrl: './my-carousel.component.html',
  styleUrl: './my-carousel.component.scss'
})
export class MyCarouselComponent implements AfterViewInit{

  cuUrl:string="https://m.media-amazon.com/images/S/pv-target-images/acf9b9510a723d3590799c6ed29afe449dba0947ee6e8fb2fab99f9e63b1ca3f._SX1080_FMjpg_.jpg";

    cardHeight: number=300  ;
    cardWidth: number=300 ;
    windowHeight: number=150  ;
    windowWidth: number=150 ;


  @Input() 
  set elementType(value: string) {
    //alert("URL setting - List View"+value);
    this._elementType = value; 
   // alert("Carousel URL Set to - "+this._myURL);
  }
  get elementType():string {
   
    return this._elementType; 
  }
  _elementType:string="";

   @Input() 
   set myURL(value: string) {
     //alert("URL setting - List View"+value);
     this._myURL = value; 
    // alert("Carousel URL Set to - "+this._myURL);
   }
   get myURL():string {
    
     return this._myURL; 
   }


   _myURL:string="";

   setWindowSize(){
    this.cardWidth=window.innerWidth*0.96;
    this.cardHeight=window.innerHeight*0.6;
   
   }
   videoClick(){
    // alert("Video Clicked ");
   }

    @HostListener('window:resize', ['$event'])
    getScreenSize() {
        
        
        this.setWindowSize();
 
       
    }
    public ngAfterViewInit() {
      this.setWindowSize();
   
  }

  
   canIshowVideo(){
      return this.elementType.toLowerCase()=="edutube";    
   }
}
