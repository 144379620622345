
<div class="container-fluid  gurukul-font-primary-style bg-secondary rounded-5 shadow-lg pb-4" >

	<div class="row">
				<h3 class="mt-5 text-primary  text-center text-hovergstylep  text-decoration-bold ">{{_listFirstTitleShowText}}</h3>
	</div>
	
    <div class="scrolling-wrapper row flex-row flex-nowrap mt-4 pt-1 pb-3 "  >
			
			<div class="col-lg-4 col-md-4 col-sm-8  "  *ngFor="let elements of currentElementListOne;let i=index;">
				<div class="card card-block card-2 shadow-md rounded-3 " 
				[style.height.px]="cardHeight"
				[style.width.px]="cardWidth"
			
				(click)="routeToShortView()" #myCard >

					<!-- <app-my-player [videoHeight]="cardHeight" [videoWidth]="cardWidth" *ngIf="canIshowVideo()"></app-my-player>			 -->
					<youtube-player
					*ngIf="canIshowVideo()"
					[width]="cardWidth" 
					[height]="cardHeight"
					videoId={{elements.image}}
					 placeholderImageQuality="high"
				   ></youtube-player>

					<img class="img-fluid rounded " src={{elements.image}} *ngIf="!canIshowVideo()" alt={{elements.name}} 

					[style.height.px]="cardHeight"
					[style.width.px]="cardWidth"> 
			
					<!-- <div id="inner" class="text-center rounded pt-2 pb-1" style=" 
					overflow-x: hidden;
					white-space: nowrap;">
					{{elements.name}}
					</div> -->
				</div>
			
			</div>
			
			
    </div>
	<div class="scrolling-wrapper row flex-row flex-nowrap mt-4 pb-4 pt-2 "  >
			
		
		<div class="col-lg-4 col-md-4 col-sm-8 " *ngFor="let elements of currentElementListSecond">
			<div class="card card-block card-2 shadow-md rounded-3 " 
		
			[style.height.px]="cardHeight"
				[style.width.px]="cardWidth"
			(click)="routeToShortView()">

			<youtube-player
			*ngIf="canIshowVideo()"
			[width]="cardWidth" 
			[height]="cardHeight"
			 videoId={{elements.image}}
			 placeholderImageQuality="high"
		   ></youtube-player>
				<!-- <app-my-player [videoHeight]="cardHeight" [videoWidth]="cardWidth" *ngIf="canIshowVideo()"></app-my-player>			 -->
				
				<img class="img-fluid rounded  " src={{elements.image}} *ngIf="!canIshowVideo()"  alt={{elements.name}} 
				
				[style.height.px]="cardHeight"
				[style.width.px]="cardWidth"
				>
				<!-- <div id="inner" class="text-center rounded pt-2 pb-1" style=" 
				overflow-x: hidden;
				white-space: nowrap;"> style=" height:27vh;"
				  {{elements.name}}
				</div> -->
			</div>
		
		</div>
		
		
	</div>
	<div class="row pt-4 "> 
		<div class="col-5 pt-4 ">
			<button type="button" class="btn btn-primary shadow-lg carousel-hovergstyles " (click)="routeToShortView()">See More {{listTitle}}</button>
	   
		</div>
	</div>	
	
</div>



