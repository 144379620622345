import { CommonModule } from '@angular/common';
import {   AfterViewInit, ChangeDetectorRef, Component, effect, ElementRef, HostListener, inject, Input, OnChanges, OnInit, ViewChild } from '@angular/core';

import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TextAreaElement } from '../../Models/Interfaces/textAreaElement';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@Component({
  selector: 'app-gen-one-list-view-static',
  imports: [CommonModule,ReactiveFormsModule,FormsModule,YouTubePlayerModule],
  providers:[],
  templateUrl: './gen-one-list-view-static.component.html',
  styleUrl: './gen-one-list-view-static.component.scss'
})
export class GenOneListViewStaticComponent implements AfterViewInit , OnChanges{
  @ViewChild('myCard') youTubePlayer:any;

  _baseLog:string="ElementListComponent\t";
  _listFirstTitleShowText:string="Institute";
  _listSeconfTitleShowText:string='"Swaraj is my birthright, and I shall have it" - Bal Gangadhar Tilak';
  cuUrl:string="https://m.media-amazon.com/images/S/pv-target-images/acf9b9510a723d3590799c6ed29afe449dba0947ee6e8fb2fab99f9e63b1ca3f._SX1080_FMjpg_.jpg";
  past_myURL="";
  isLoggedIn=false;
  currentElementListOne:TextAreaElement[]=[];
  currentElementListSecond:TextAreaElement[]=[];



  _isVideoEnabled:boolean=false;
    @Input() 
    set listTitle(value: string) 
    {
      this._listTitle = value; 
      this._listFirstTitleShowText=this._listTitle;
      this.myURL=sessionStorage.getItem("userViewUrlPref")+"/"+this._listTitle.toString().toLowerCase()+"/tags/POPULAR/";           
     
      this.updateListSeconfTitleShowText();

    
    }
    get listTitle():string {
      return this._listTitle; 
    }
    _listTitle:string="List-Unknown";

    @Input()
    set fromHome(value: string) {
      // //////alert(this._baseLog+"fromHome Set - "+value);
      this._fromHome=value;
     
    }
    get fromHome():string {      
      return this._fromHome; 
    }
    _fromHome:string="false";
    
 
   
    cardHeight: number=150  ;
    cardWidth: number=150 ;
    windowHeight: number=150  ;
    windowWidth: number=150 ;
  
  
      set myURL(value: string) {
        if(value!=null&&value!=undefined&&value.length>6&&value!=this._myURL){
          this._myURL = value;          
          this.doOnChanges();
        }
       
      }
      get myURL():string {
        
        return this._myURL; 
      }
      private _myURL:string="";
      
      http=inject(HttpClient);
    
      setWindowSize(){
        this.windowWidth=window.innerWidth;
        this.windowHeight=window.innerHeight;
        this.cardHeight=this.windowHeight/3;
        if(this.windowWidth>800)
          {
            this.cardWidth=this.windowWidth*0.29;
     
          }
        else if(this.windowWidth>600)
          {
            this.cardWidth=this.windowWidth*0.55;
           
          }
        else 
            {
              this.cardWidth=this.windowWidth*0.8;
              
            }
       
       }
    
      ngOnChanges(changes:any) {
        // alert('Change detected:'+ changes);
        // this.getSize();
 
      }
    
      

    constructor( private router:Router,private changeDetectorRef: ChangeDetectorRef){
    
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize() {
        
        
        this.setWindowSize();
 
       
    }


    public ngAfterViewInit() {
      this.changeDetectorRef.detectChanges();
      this.setWindowSize();
   
  }
    isHomePage(){
      return (this.fromHome=="inHome")?true:false;
    }

    canIshowVideo(){
      return this._isVideoEnabled;
    }



    updateListSeconfTitleShowText(){
      this._isVideoEnabled=false;
      if(this.listTitle.toLowerCase()=="institute"){
        this._listFirstTitleShowText="Institutions".toUpperCase();
        this._listSeconfTitleShowText="Empowering Your Institutional Journey";
      }
      if(this.listTitle.toLowerCase()=="scholarship"){
        this._listFirstTitleShowText="Scholarships".toUpperCase();
        this._listSeconfTitleShowText="Scholarships at Your Fingertips";
      }
      if(this.listTitle.toLowerCase()=="edutube"){
        this._isVideoEnabled=true;
        this._listFirstTitleShowText="Edutube".toUpperCase();
        this._listSeconfTitleShowText="Transfer Your Future with the Right Content";
      }
      if(this.listTitle.toLowerCase()=="teacher"){
        this._listFirstTitleShowText="Teachers".toUpperCase();
        this._listSeconfTitleShowText="Your Video Guide to Knowledge";
      }
      if(this.listTitle.toLowerCase()=="course"){
        this._listFirstTitleShowText="Courses".toUpperCase();
        this._listSeconfTitleShowText="Guiding Lights for Brighter Futures";
      }
    }
    
    doOnChanges(){
      
       this.doUserDataLoading(undefined);
    }

    routeToShortView(){
      //////alert("RAM Ji Element Selected element-List"+selectedElement?.name+selectedElement?.id);
    
        // alert("RAM Ji Routing to  "+this.listTitle);
        this.router.navigate(['short-view'],{
          queryParams: {
            elementType:this.listTitle,
            selectedElementId:"RAM",
            elementListUrl:this.myURL,
            fromHome:this.fromHome
        }});
    
    }
    getUrlId(url:string){
      // alert(" Video ID >>> "+  (url.split("=")[1]).split("&")[0]);
      return (url.split("=")[1]).split("&")[0];
    }
    getDataAssigned(response:any){
      for(let i=0;i<response.length&&i<3;i++){
        let obj:TextAreaElement=response[i];
        if(this.listTitle.toLowerCase()=="edutube"&&obj.image!=undefined){
          obj.image=this.getUrlId(obj.image);
        }
        this.currentElementListOne.push(obj);
      }
      for(let i=3;i<response.length&&i<6;i++){
        let obj:TextAreaElement=response[i];
        if(this.listTitle.toLowerCase()=="edutube"&&obj.image!=undefined){
          obj.image=this.getUrlId(obj.image);
        }
        this.currentElementListSecond.push(response[i]);
      }
     
    }
  
    
  doUserDataLoading(lastId:string|undefined){
      //////alert(this._baseLog+"doUserDataLoading API CALL START" )
      if(this.myURL!=undefined&&this.myURL!=this.past_myURL){
        // alert(this._baseLog+"doUserDataLoading API CALL Verified" +this.myURL)
        this.past_myURL=this.myURL;
        this.http.get((lastId!=undefined)?""+this.myURL+"?id="+lastId:this.myURL).subscribe((response:any)=>{
        
        this.getDataAssigned(response);
        // alert(this._baseLog+"doUserDataLoading RAMA Got Normal Response "+JSON.stringify(response));
        
        
      if(this.fromHome!="yes"){

        //! this.elementUpdateService.updateSelectedItemData(this.currentElementList[0]);
      }

      });
      }
  }
}   

