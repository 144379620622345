import { Component, effect, inject, Input, OnInit } from '@angular/core';

import {FormsModule} from "@angular/forms";

import { MyCarouselComponent } from '../my-carousel/my-carousel.component';
import { SelectedElementService } from '../../Services/selected-element.service';
import { MyTextAreaComponent } from '../my-text-area/my-text-area.component';
import { LoginService } from '../../Services/login.service';
import { LoginResponse } from '../../Models/Interfaces/loginResponse';
import { AdminControlsComponent } from '../admin-controls/admin-controls.component';
import { CommonModule } from '@angular/common';
import { TextAreaElement } from '../../Models/Interfaces/textAreaElement';


@Component({
  selector: 'app-element-body',
  standalone:true,
  imports: [MyCarouselComponent,FormsModule,CommonModule,MyTextAreaComponent,AdminControlsComponent],
  templateUrl: './element-body.component.html',
  styleUrl: './element-body.component.scss'
})
export class ElementBodyComponent implements OnInit{

  isAdminLogin:boolean=false;
  userLoggedInData?:LoginResponse;

  // adminViewService:AdminViewService=AdminViewService.getInstance();



  @Input() 
  set elementType(value: string) {
    
    this._elementType = value; 
    // this.adminViewService.setElementType( this._elementType);

  }
  get elementType():string {
    return this._elementType; 
  }

  _elementType:string="DefaultFrom ElementBody";

  selectedElementSuper:TextAreaElement=new TextAreaElement("","","");

  displayStyle = "none"; 
  
  currentElementId:string='0194b77a-e168-7622-8af8-d49 e8f88b85c';
  currentElementImageUrl:string="";
  bodyData=new Map<string,string>();
  
  curElementName:string="";

  // setAdminLogin(){
  //   if(this.userLoggedInData!=undefined&&(this.userLoggedInData.typeOfMember=="MSIT"||this.userLoggedInData.typeOfMember=="SUPER-ADMIN"||this.userLoggedInData.typeOfMember=="ADMIN")){
  //     this.isAdminLogin=true;
  //     this.adminViewService.setElementType(this._elementType);
  //   }else{
  //     this.isAdminLogin=false;
  //   }
  // }

  constructor(public elementUpdateService:SelectedElementService,private loginService:LoginService){

    // this.userLoggedInData=this.loginService.getLoginDetails();
    //   effect(()=>{
    //     this.userLoggedInData=this.loginService.getLoginDetails();
    //     this.setAdminLogin();
    //     ////alert("EBody LoginService - Effect  ");
    //   });     

    this.isAdminLogin=loginService.getLoginStatus();
    effect(()=>{
      this.isAdminLogin=loginService.getLoginStatus();
    });

    effect(()=>{
      this.selectedElementSuper=this.elementUpdateService.getSelectedItem();
      window.scrollTo(0, 0);//! Go to Top on Changes
      this.currentElementImageUrl=(this.selectedElementSuper.image!=undefined)?this.selectedElementSuper.image:"";
      this.currentElementId=(this.selectedElementSuper.id!=undefined)?this.selectedElementSuper.id:"ID Not Found EBody";
      this.curElementName= (this.selectedElementSuper.name!=undefined)?this.selectedElementSuper.name:"ID Not Found EBody";
      ////alert("EBody SelectedElementService - Effect  ");
    });
    
    ////alert("Current Selected Item we have Ram Ji "+this.selectedElementSuper.id);
  }

  //! switchAdminView(){
  //    this.currentViewService.setView("admin-view");
  // }

 

  isAdminLogins(){
    return this.isAdminLogin;
  }

  ngOnInit(): void{
     this.getAllElements();
   }
 
   getAllElements(){
    // //alert("Checking URL"+this.myURL);
     //this.http.get("http://localhost:9201/users-area-ele-views/institute/tags/POPULAR/").subscribe((res:any)=>{
    //  if(this.currentElementId!=undefined){
    //  this.http.get(sessionStorage.get).subscribe((res:any)=>{
    //    this.instituteElementList=res;
      
    //  });
   }
}




