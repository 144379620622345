import { RouterModule, Routes } from '@angular/router';
import { HomeBodyComponent } from './Components/home-body/home-body.component';
import { ElementShortViewComponent } from './Components/element-short-view/element-short-view.component';


export const routes: Routes = [
    
    {
        path:'',
        component: HomeBodyComponent
        //component:HomeBodyComponentElementFormComponent

    },
    {
        path:'home',
        component:HomeBodyComponent
    }, 
    {
        path:'short-view',
        data:{elementname:'RAMARAMALAKSHMAN'},
        component:ElementShortViewComponent
    }
    
];
