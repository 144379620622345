<!-- Button trigger modal -->

<div class="container bg-secondary rounded-3 shadow-md">    
  <div class="row p-3">
    <h4 class="h4 text-primary text-center text-decoration-underline">
      Different Filter Types we have for {{curElementType}}
    </h4>
  </div>
    <div class="row p-3">
        <div class="col-fluid col-lg-3 col-md-4 col-sm-12 ps-1 ">
            <button type="button"
            class="btn btn-tertiary shadow-lg text-hovergstyles" 
            (click)="openModalNow()">
              Search with Names 
            </button>
        </div>
        <div class="col-fluid col-lg-3 col-md-4 col-sm-12 ps-1 pe-1 pt-1">
            <button type="button" class="btn btn-tertiary text-hovergstyles" (click)="openModalNow()">
            Search Based on Locations
            </button>
        </div>
        <div class="col-fluid col-lg-3 col-md-4 col-sm-12 ps-1 pe-1 pt-1">
            <button type="button" class="btn btn-tertiary text-hovergstyles" (click)="openModalNow()">
            Search with Different Filters
            </button>
        </div>
    </div>
</div>
<div *ngIf="curElementType!=undefined">
  <div class="modal " id="{{curElementType}}-UserFilterModal" tabindex="-1" role="dialog" >
    <div class="modal-dialog modal-lg " role="document">
      <div class="modal-content bg-secondary">
        <div class="modal-header">
          <h6 class="modal-title text-primary">Search Options For {{curElementType}}</h6>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12">
              <p class="text-end text-primary">Name</p>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12"  >
              <input #NameInput type="text" class="form-control  text-primary" placeholder="Search A Name" aria-label="Name" 
              (keyup)="nameChange(NameInput.value)" aria-describedby="basic-addon1">
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
              <p class="text-end text-primary">Address</p>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <input #AddressInput type="text" class="form-control text-primary" placeholder="Search with any Address" aria-label="Address" 
              (keyup)="addressChange(AddressInput.value)" aria-describedby="basic-addon1">
            </div>
          </div>
        </div>
        <div class="modal-header">
          <h6 class="modal-title text-primary">Panditya Tags</h6>
        </div>
        <div class="modal-body">
          <div class="row">
            
              <div class="col" *ngFor="let tag of allTags">
                <input type="radio" class="btn-check" name="tag" id="{{tag}}" autocomplete="off" 
                [checked]="(tag==allTagSelected)" (click)="allTagChange(tag)">
                    <label class="btn btn-outline-primary" for="{{tag}}">{{tag}}</label>                   
             </div>
          </div>
        </div>
        <div class="modal-header">
          <h6 class="modal-title text-primary">{{curElementType}} Tags</h6>
        </div>
        <div class="modal-body">
          <div class="row">
           
            <div class="col" *ngFor="let tag of curElementTags">
                <input type="radio" class="btn-check" name="etag" id="{{tag}}" autocomplete="off"
                [checked]="(tag==eTagSelected)" (click)="eTagChange(tag)">
                    <label class="btn btn-outline-primary" for="{{tag}}">{{tag}}</label>                   
             </div>
          </div>
        </div>
       
          <div class="modal-footer ">
            <button type="button" class="btn btn-info" (click)="onSearchRequest()">Search</button>
            <button type="button" class="btn btn-warning" data-bs-dismiss="modal" (click)="closeModalNow()">Close</button>
          </div>
      
      </div>
    </div>
  </div>
</div>