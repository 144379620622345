import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import {  inject, Injectable, signal } from '@angular/core';
import { LoginService } from './login.service';
import { TextAreaElement } from '../Models/Interfaces/textAreaElement';

import swal from 'sweetalert2';
@Injectable()
export class APICallerGenOneService {

  static _serviceReference?:APICallerGenOneService;
  _curApproveCount=signal(Map);
  _curNotApproveCount=signal(Map);

  http=inject(HttpClient);
  
  
  private loginService=inject(LoginService);


  constructor(){}

  static getInstance():APICallerGenOneService{
    if(APICallerGenOneService._serviceReference==undefined||APICallerGenOneService._serviceReference==null){
      APICallerGenOneService._serviceReference=new APICallerGenOneService();
    }
    return APICallerGenOneService._serviceReference;
  }

  getCurNotApproveCount(){
    return this._curNotApproveCount();
  }
  setCurNotApproveCount(data:any){
    this._curNotApproveCount.set(data);
  }

  getCurApproveCount(){
    return this._curApproveCount();
  }
  setCurApproveCount(data:any){
    this._curApproveCount.set(data);
  }

  getAuthToken(){
    return this.loginService?.loginResponse?.access_token ;
  }

  callApprovedCount(searchMsg:string,myUrl:string){


    //alert("Token ready to Call "+this.loginService?.loginResponse?.access_token);
    try{

       if(myUrl!=undefined){
          

          var reqHeader = new HttpHeaders({ 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAuthToken()
         });

            this.http.get(myUrl, { headers: reqHeader }
               
            )
            
            .subscribe(
            (response) => {  
              
                this.setCurApproveCount(response);
                // alert(searchMsg+" -- "+JSON.stringify(response));
            },
            (error) => {  
                alert("Error Calling Inside"+searchMsg+" With Url "+myUrl+" -- Error "+JSON.stringify(error));
                  }
                );

            }
    }catch(e){
      alert("Error while Calling "+searchMsg+" With Url "+myUrl);
    }
  }

  callNotApprovedCount(searchMsg:string,myUrl:string){


    //alert("Token ready to Call "+this.loginService?.loginResponse?.access_token);
    try{

       if(myUrl!=undefined){
          

          var reqHeader = new HttpHeaders({ 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAuthToken()
         });

            this.http.get(myUrl, { headers: reqHeader }
               
            )
            
            .subscribe(
            (response) => {  
              
                this.setCurNotApproveCount(response);
                // alert(searchMsg+" -- "+JSON.stringify(response));
            },
            (error) => {  
                alert("Error Calling Inside"+searchMsg+" With Url "+myUrl+" -- Error "+JSON.stringify(error));
                  }
                );

            }
    }catch(e){
      alert("Error while Calling "+searchMsg+" With Url "+myUrl);
    }
  }
  
  // callApproveApi_ONE(searchMsg:string,myUrl:string){


  //   alert("callApproveApi ready to Call "+myUrl);
  //   try{

  //      if(myUrl!=undefined){
          
  //       var reqHeader = new HttpHeaders({ 
  //         'Content-Type': 'application/json',
  //         'Authorization': 'Bearer ' + this.getAuthToken()
  //      });

  //           this.http.post(myUrl, { headers: reqHeader }  )            
  //           .subscribe(
  //           (response) => {  
              
              
  //               alert(searchMsg+" -POST- "+JSON.stringify(response));
  //           },
  //           (error) => {  
  //               alert("Error Calling Inside"+searchMsg+" With Url "+myUrl+" -- Error "+JSON.stringify(error));
  //                 }
  //               );

  //           }
  //   }catch(e){
  //     alert("Error while Calling "+searchMsg+" With Url "+myUrl);
  //   }
  // }
  

  callApproveApi(searchMsg:string,myUrl:string){


    // alert("callApproveApi ready to Call "+myUrl);
    try{

       if(myUrl!=undefined){
          

          var reqHeader = new HttpHeaders({ 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAuthToken()
         });

            this.http.post(myUrl, {},{ headers: reqHeader })
            
            .subscribe(
            (response) => {  
              
              
                // alert(searchMsg+" -- "+JSON.stringify(response));
               
                swal.fire("Approve Action!", "Approve Element  "+(<TextAreaElement>response).name  +" Completed", "success");
            },
            (error) => { 
              swal.fire("Element Creation Error Inside ","Element Not Created Error"+JSON.stringify(error),"error");
           
                // alert("Error Calling Inside"+searchMsg+" With Url "+myUrl+" -- Error "+JSON.stringify(error));
                  }
                );

            }
    }catch(e){
      swal.fire("Element Creation Error Inside ","Element Not Created Error"+JSON.stringify(e),"error");
          
      // alert("Error while Calling "+searchMsg+" With Url "+myUrl);
    }
  }


  callDeleteApi(searchMsg:string,myUrl:string){


    //alert("Token ready to Call "+this.loginService?.loginResponse?.access_token);
    try{

       if(myUrl!=undefined){
          

          var reqHeader = new HttpHeaders({ 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAuthToken()
         });

            this.http.delete(myUrl, { headers: reqHeader }
               
            )
            
            .subscribe(
            (response) => {  
              
              
                // alert(searchMsg+" -- "+JSON.stringify(response));
                
                swal.fire("Delete Action!", "Delete Element "+(<TextAreaElement>response).name +" Completed", "success");
            },
            (error) => {  
              swal.fire("Element Creation Error Inside ","Element Not Created Error"+JSON.stringify(error),"error");
                // alert("Error Calling Inside"+searchMsg+" With Url "+myUrl+" -- Error "+JSON.stringify(error));
                  }
                );

            }
    }catch(e){
      swal.fire("Element Creation Error","Element Not Created Error"+JSON.stringify(e),"error");
    }
  }

  print(err:string):any{
    return true;
  }


  
  postElementData(curMsg:string,myUrl:string,curObj:TextAreaElement){
    try{
     
      if(myUrl!=undefined){
         

         var reqHeader = new HttpHeaders({ 
           'Content-Type': 'application/json',
           'Authorization': 'Bearer ' + this.getAuthToken()
        });

           this.http.post<HttpResponse<any>>(myUrl, curObj,{ headers: reqHeader })
           
           .subscribe(
            (response: HttpResponse<any>) => {
              try{
                if((<TextAreaElement>response).name==undefined){
                  
                  swal.fire("Element Creation Error Inside","Element Not Created Error"+JSON.stringify(response,null,1),"error");
                }else{
                  // alert(" Element Post Request Success  "+((<TextAreaElement>response).name))
                  swal.fire("Element Creation","Element Created "+(<TextAreaElement>response).name,"success");
                }
               
              }catch(e){}
              
            },
           (error) => { 
            swal.fire("Element Creation Error","Element Not Created Error"+JSON.stringify(error),"error");
          
              //  alert("Error Calling "+curMsg+" With Url "+myUrl+" -- Error "+JSON.stringify(error));
                 }
               );

           }
   }catch(e){
     alert("Error while Calling "+curMsg+" With Url "+myUrl);
   }

  }
}
