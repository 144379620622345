import { Component } from '@angular/core';
import { PageGenOneComponent } from '../page-gen-one/page-gen-one.component';


@Component({
  selector: 'app-my-navbar',
  imports: [PageGenOneComponent],
  templateUrl: './my-navbar.component.html',
  styleUrl: './my-navbar.component.scss'
})
export class MyNavbarComponent {
  
  requestedPageName:string="";
  
  aboutUs:string="Welcome to InGurukul, a revolutionary educational platform designed to transform the way we learn and grow. Our mission is to provide accessible, affordable, and high-quality education to students of all ages and backgrounds.At InGurukul, we believe that education is the key to unlocking individual potential and shaping a brighter future. Our platform offers a comprehensive range of educational resources, including interactive lessons, video tutorials, online courses, and personalized learning tools.";
  pageGenOneComponent=new PageGenOneComponent();
  constructor(){
    // alert("Data From JSON "+JSON.stringify(metaData));
  }

  openPageGenOneComponent(pageName:string){
    this.requestedPageName=pageName;
    // alert("Calling for "+pageName);
    this.pageGenOneComponent.openModalNow(pageName,"");
  }

  
}






