<div class="row p-2"></div>
<div class="container-fluid" *ngIf="!isLoggedIn">
	
	<div class="row">
		<div class="row p-2"></div>
		<app-panditya-filter ></app-panditya-filter>
	</div>
</div>
<div class="container-fluid" >

	
	<div class="row" >
		<h4 class="mt-5 text-secondary text-decoration-bold text-decoration-underline">{{_listFirstTitleShowText}}</h4>
		<p class="subtitle text-secondary ">{{_listSeconfTitleShowText}}</p>
	</div>
     
    <div class="scrolling-wrapper row flex-row flex-nowrap mt-4 pb-4 pt-2"  >
			
		
			<div class="col-lg-4 col-md-6 col-sm-8" *ngFor="let elements of currentElementList">
				
				
				<div class="card card-block card-2 shadow rounded-3 " 
				[style.height.px]="cardHeight"
				[style.width.px]="cardWidth"
			
				(click)="setSelectedElement(elements)"  >

				<!-- <div class="card card-block card-2" (click)="setSelectedElement(elements)"> -->
					
					<!-- <app-my-player [videoHeight]="cardHeight" [videoWidth]="cardWidth" *ngIf="canIshowVideo()"></app-my-player>			
				 -->
				 <youtube-player
				 *ngIf="canIshowVideo()"
				 [width]="cardWidth" 
				 [height]="cardHeight"
				  videoId={{elements.image}}
				  placeholderImageQuality="high"
				></youtube-player>

				<img class="img-fluid rounded shadow-lg" src={{elements.image}} 
				*ngIf="!canIshowVideo()"  alt={{elements.name}} 	
				[style.height.px]="cardHeight-30"
				[style.width.px]="cardWidth"> 
          
          
					<!-- <p class="text-center  pt-0 pb-0 text-truncate" style="width: 6rem;" ></p>
				  -->
					<div id="inner" class="text-center rounded pt-1 pb-0 " style=" 
					overflow-x: hidden;
					white-space: nowrap;">
					  {{elements.name}}
					</div>
				</div>
			
			</div>
		
    </div>

	
</div>

<div class="row justify-content-end align-items-end pt-4 "> 
		<div class="col-5 pt-4 ">
			<button type="button" class="btn btn-tertiary bg-secondary" (click)="loadMoreElements()">Load More related {{listTitle}}</button>
	   
		</div>
</div>

