import { Injectable, signal } from '@angular/core';

@Injectable()
export class FilterDynamicSearchService {

  private _selectedElementType=signal("Institute");
  private _currentElementTag:string|undefined;
  private _currentTag:string|undefined;
  private _currentElementName:string|undefined;
  private _currentElementAddress:string|undefined;
  private _currentElementSearchUrl=signal("");
  private _baseUrl:string|null;

    constructor(){
      this._baseUrl=sessionStorage.getItem("userViewUrlPref");
      // !this.updateAdminQuery("allapproved");
    }

  

    changeBaseURL(curUrl:string){
      this._baseUrl=curUrl;
      ////////alert("DynamicSearch - base url change "+this._baseUrl);
    }


    isEmpty(data:string|undefined):boolean{
      return data==undefined;
    }

    setElementType(curElementType:string){     
      //alert("Dynamic Search Element Type Set - "+curElementType);      
      this._selectedElementType.set(curElementType);
      this.setCurrentElementFilterUrl(this._baseUrl+"/"+this._selectedElementType().toString().toLowerCase()+"/tags/POPULAR/");
    }

    getElementType():string{
      return this._selectedElementType();
    }
 
  
   
    getCurrentElementFilterUrl():string{
      return this._currentElementSearchUrl();
    }
  
    private setCurrentElementFilterUrl(curUrl:string){
      return this._currentElementSearchUrl.set(curUrl);
    }

    updateFilterData(curFilters:Map<string,string>){
      
      let filters:string="";

      // let currentFilterData:Map<string,string>=new Map<string,string>();
      if(!this.isEmpty(curFilters.get("etag"))
       // &&curFilters.get("etag")!=this._currentElementTag
      ){
          this._currentElementTag=curFilters.get("etag");
          filters+="etag="+this._currentElementTag;
      }
      if(!this.isEmpty(curFilters.get("tag"))
        //&&curFilters.get("tag")!=this._currentTag
      ){
          this._currentTag=curFilters.get("tag");
          filters+=(filters.length>1)?"&tag="+this._currentTag:"tag="+this._currentTag;
      }
      if(!this.isEmpty(curFilters.get("name"))
       // &&curFilters.get("name")!=this._currentElementName
      ){
        this._currentElementName=curFilters.get("name");
        filters+=(filters.length>1)?"&name="+this._currentElementName:"name="+this._currentElementName;
      }
      if(!this.isEmpty(curFilters.get("address"))
       // &&curFilters.get("address")!=this._currentElementAddress
      ){
        this._currentElementAddress=curFilters.get("address");
        filters+=(filters.length>1)?"&address="+this._currentElementAddress:"address="+this._currentElementAddress;
      }
      if(filters.length>1){
        if(this._baseUrl==null){this._baseUrl="Base URL Not Found Filter-Service";}
        this._currentElementSearchUrl.set(this._baseUrl+"/"+this._selectedElementType().toString().toLowerCase()+"/dynamicSearch?"+filters);
        // alert("FilterServices New Search"+this._currentElementSearchUrl());
      }
   }
}
