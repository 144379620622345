import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ElementTagService {

    http=inject(HttpClient);
  
  
    private _allTag=signal([]);
     
    constructor(){}
  
    isEmpty(data:string|undefined):boolean{
      return data==undefined;
    }


    getAllTag():any{
      if(this._allTag().length==0){
        this.setAllTag();
      }

      return this._allTag();
    }

  
  setAllTag(){
   
      //alert("Set ALlTags "+this._allTag().length);
    
      let curUrl:string=sessionStorage.getItem("userViewUrlPref")+"/alltags";
      this.callApi(curUrl);
      
    
  }


    
  callApi(url:string){

      this.http.get(url).subscribe((res:any)=>{
        //
        this._allTag.set(res);

       // alert("TagServiceFilter - "+url+" -- "+this._allTag());
        
      });
  }


}
