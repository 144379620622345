import { Component } from '@angular/core';
import {  } from '../gen-one-list-view/gen-one-list-view.component';
import { GenOneHomeCarouselComponent } from '../gen-one-home-carousel/gen-one-home-carousel.component';
import { GenOneListViewStaticComponent } from '../gen-one-list-view-static/gen-one-list-view-static.component';
@Component({
  selector: 'app-home-body',
  imports: [GenOneListViewStaticComponent,GenOneHomeCarouselComponent],
  templateUrl: './home-body.component.html',
  styleUrl: './home-body.component.scss'
})
export class HomeBodyComponent {
  institute:string="Institute";
  course:string="Course";
  teacher:string="Teacher";
  edutube:string="Edutube";
  scholarship:string="Scholarship";
  elementInstituteUrl:string="";
  elementCourseUrl:string="";
  elementTeacherUrl:string="";
  elementScholarshipUrl:string="";
  elementEdutubeUrl:string="";
  stateComment:string="inHome";
  homePageImageUrl:string='https://img.freepik.com/premium-photo/watercolor-lord-shree-ram-image-background_669954-62549.jpg'

checkNull(value:any):string{
  if(value==undefined||value==null){
    return "NOFD - Session Storage - HomeBody";
  }
  else return value;
}

constructor(){
 
    this.elementInstituteUrl=this.checkNull(sessionStorage.getItem("elementInstituteUrl"));
    this.elementCourseUrl=this.checkNull(sessionStorage.getItem("elementCourseUrl"));
    this.elementTeacherUrl=this.checkNull(sessionStorage.getItem("elementTeacherUrl"));
    this.elementScholarshipUrl=this.checkNull(sessionStorage.getItem("elementScholarshipUrl"));
    this.elementEdutubeUrl=this.checkNull(sessionStorage.getItem("elementEdutubeUrl"));


}

} 


