

  <div class="modal col-lg-3 col-md-12 col-sm-12 " id="LoginModelForm" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg " role="document">
      <div class="modal-content bg-primary ">
        <div class="modal-header">
          <div class="row ps-3 justify-content-center align-items-center ">
            <h5 class="modal-title text-center text-secondary">inGurukul Members Login Page</h5>
          </div>
        </div>
        <div class="modal-body">
          <div class="row pt-1">
            <div class="col-lg-12">
            </div> 
            <div class=" col-lg-6 col-md-10 col-sm-12 pt-3 ps-5 pe-5 pb-3 "  >
              <label class="pb-3 text-secondary" for="dropdownInput ">Enter ID</label>
              <input #NameInput type="text" class="form-control" placeholder="inGurukul id" aria-label="Name" 
              (keyup)="idChange(NameInput.value)" >
            </div>
            <div class=" col-lg-6 col-md-10 col-sm-12 pt-3 ps-5 pe-5 pb-2">
              <label class="pb-3 text-secondary" for="dropdownInput ">Enter Password</label>
              <input #LPassword [type]="visiblePasswords ? 'text' : 'password'" class="form-control" placeholder="Password" aria-label="Password" type="password" 
              (keyup)="passwordChange(LPassword.value)">
            </div>
            <div class="row ps-2" *ngIf="changePassword">   
              <div class=" col-lg-6 col-md-10 col-sm-12 pt-3 ps-5 pe-5 pb-2">
                <label class="pb-3 text-secondary" for="dropdownInput ">New Password</label>
                <input #NPassword id="NPassword" [type]="visiblePasswords ? 'text' : 'password'" class="form-control" placeholder="New Password" aria-label="Password" type="password" 
                (keyup)="passwordChangeNew(NPassword.value)">
              </div>           
              <div class=" col-lg-6 col-md-10 col-sm-12 pt-3 ps-5 pe-5 pb-2">
                <label class="pb-3 text-secondary" for="dropdownInput ">Confirm Password</label>
                <input #CPassword id="CPassword" [type]="visiblePasswords ? 'text' : 'password'" class="form-control" placeholder="Confirm Password" aria-label="Password" type="password" 
                (keyup)="passwordChangeConfirm(CPassword.value)">
              </div>            
            </div>            
          </div>
        </div>
        <div class="modal-footer p-2">
          <button type="button" class="btn btn-primary" (click)="showPasswords()">Show Password</button>
          <button type="button" class="btn btn-primary" (click)="enableChangePassword()">Change Password</button>
          <button *ngIf="!changePassword" type="button" class="btn btn-primary" (click)="onLoginClicked()">Login</button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="closeLoginModal()">Close</button>          
        </div>
      </div>
    </div>
  </div> 

