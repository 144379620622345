import { Component, OnInit } from '@angular/core';

import {MyHeaderComponent} from './Components/my-header/my-header.component';
import { MyNavbarComponent } from './Components/my-navbar/my-navbar.component';
import { LoginService } from './Services/login.service';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomLocalStorageService } from './Services/custom-local-storage.service';
import { ElementTagService } from './Services/element-tag.service';
import { RouterLink, RouterLinkActive, RouterModule, RouterOutlet } from '@angular/router';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
// import { ElementBodyComponent } from './Components/element-body/element-body.component';

import { routes } from './app.routes';

// {provide:LocationStrategy,useClass:HashLocationStrategy}



@Component({
  selector: 'app-root',
  imports: [MyHeaderComponent,MyNavbarComponent,ReactiveFormsModule],
  providers:[LoginService,ElementTagService,CustomLocalStorageService, RouterLink, RouterLinkActive, RouterOutlet,RouterModule,
   ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {


  myUrl:string='RAMSITARAMLAKSHMANHANUMAN';
  title = 'InGurukul';

  constructor(){
    AppComponent.storeLocalData();
  }

  private static storeLocalData(){
    // alert("storeLocalData-  ");

    sessionStorage.setItem("appTitle","InGurukul");

    //!Admin URLS
    sessionStorage.setItem("adminelemgmt","https://80.65.208.219:8080/admin-ele-mgmt/");
    sessionStorage.setItem("supadminelemgmt","https://80.65.208.219:8080/supadmin-ele-mgmt/");

    //!NoAuth URLS
    sessionStorage.setItem("loginUrl","https://80.65.208.219:8080/noauth/login");
    sessionStorage.setItem("changepassword","https://80.65.208.219:8080/noauth/changepassword");
    sessionStorage.setItem("userViewUrlPref","https://80.65.208.219:8080/users-area-ele-views");
    sessionStorage.setItem("elementInstituteUrl","https://80.65.208.219:8080/users-area-ele-views/institute/tags/POPULAR/");
    sessionStorage.setItem("elementCourseUrl","https://80.65.208.219:8080/users-area-ele-views/course/tags/POPULAR/");
    sessionStorage.setItem("elementTeacherUrl","https://80.65.208.219:8080/users-area-ele-views/teacher/tags/POPULAR/");
    sessionStorage.setItem("elementScholarshipUrl","https://80.65.208.219:8080/users-area-ele-views/scholarship/tags/POPULAR/");
    sessionStorage.setItem("elementEdutubeUrl","https://80.65.208.219:8080/users-area-ele-views/edutube/tags/POPULAR/");
    
    // //alert("storing local Data completed "+sessionStorage.getItem("appTitle"));
    // //alert("storing local Data completed "+sessionStorage.getItem("appTitle"));
    //sessionStorage.setItem("elementInstituteUrl","http://80.65.208.219:8080/users-area-ele-views/institute/tags/POPULAR/");
    //sessionStorage.setItem("elementCourseUrl","http://80.65.208.219:8080/users-area-ele-views/institute/tags/POPULAR/");
    //sessionStorage.setItem("elementTeacherUrl","http://80.65.208.219:8080/users-area-ele-views/institute/tags/POPULAR/");
    //sessionStorage.setItem("elementScholarshipUrl","http://80.65.208.219:8080/users-area-ele-views/institute/tags/POPULAR/");
    //sessionStorage.setItem("elementEdutubeUrl","http://80.65.208.219:8080/users-area-ele-views/institute/tags/POPULAR/");
    
  }
}
