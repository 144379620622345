import { Component, effect, inject, OnInit, signal } from '@angular/core';
import { RouterOutlet,RouterLink } from '@angular/router';
import { LoginFormComponent } from '../login-form/login-form.component';
import { LoginService } from '../../Services/login.service';
import { CommonModule, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LoginResponse } from '../../Models/Interfaces/loginResponse';
import swal from 'sweetalert2';
@Component({
  selector: 'app-my-header',
  imports: [RouterOutlet,RouterLink,LoginFormComponent,CommonModule,FormsModule,NgFor],
  templateUrl: './my-header.component.html',
  styleUrl: './my-header.component.scss'
})
export class MyHeaderComponent {

  
  _baseLog="MyHeaderComponent ";
  allElements=[
    "Institute",
    "Scholarship",
    "Course",
    "Teacher",
    "Edutube"
  ];

  isAdminLogin:boolean=false;
  logInTitle:string="Login";
  userLoggedInData?:LoginResponse;
  loginService:LoginService = inject(LoginService);

   myLoginModel= new LoginFormComponent();


  constructor(){
   
    this.userLoggedInData=this.loginService.getLoginDetails();
    effect(()=>{
           this.userLoggedInData=this.loginService.getLoginDetails();
          this.logInTitle=(this.userLoggedInData.email_id!=undefined)?this.userLoggedInData.email_id+"-Logout":"Login";
          this.setAdminLogin();
         });     
  }

  setAdminLogin(){
    if(this.userLoggedInData!=undefined&&(this.userLoggedInData.role_type=="MSIT"||this.userLoggedInData.role_type=="SUPER-ADMIN"||this.userLoggedInData.role_type=="ADMIN")){
      this.isAdminLogin=true;
    }else{
      this.isAdminLogin=false;
    }
  }

  

  trackByName(index:number,element:any){
    // alert(this._baseLog+" trackByName "+index);
     return index;
  }

  isAdminLogins(){
    return this.isAdminLogin;
  }

  openLoginModal(){
    // alert("Open Login Model");
    if(this.logInTitle=="Login"){
      this.myLoginModel.openLoginModal();
    }else{
      swal.fire({
        title: "Do you want to Logout?",
        showDenyButton: true,
        confirmButtonText: "LogOut",
        denyButtonText: `Cancel`
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.loginService.takeAllActionsForLogOut();
       this.myLoginModel.openLoginModal();
          swal.fire("Logout Action!", "Logout-Success", "success");
        }
      });

      
    
    }
  }


}
