export class LoginResponse{
   
    access_token:string |undefined;    
    expires_in:string|undefined ;
    email_id?:string ;
    isLoggedIn:boolean=false;
    role_type:string="Admin";
    errorMessage:string="Default Error From Login Response Please change";

    constructor( access_token?:string , expires_in?:string ) {
        this.access_token = access_token;
        this.expires_in=expires_in;
        // this.userName=(userName==undefined||userName==null)?"RAM":userName;


    }

}