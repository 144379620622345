


export class PageModels {

    id?:string;
    pageName?:string;
    created_on?:string;
    allParagraphs?:any;

    constructor(id:string,pageName:string,created_on:string,allParagraphs:any){
      this.id=id;
      this.pageName=pageName;
      this.allParagraphs=allParagraphs;
      this.created_on=created_on;
    }


}


  