


<app-element-form [curElementType]="curElementType"></app-element-form>
        
        
<div class="row pt-4 rounded-5"></div>

<div class="container bg-primary p-4 rounded-5">
    <div class="row justify-content-center ">
        <div class="col col-lg-10 col-md-10 col-sm-12 rounded-5  bg-secondary">
            
            <div class="row justify-content-center ">
                <div class="col col-lg-12 col-md-12 col-sm-12 pt-2 ps-3 bg-secondary">
                    <h4>
                        Admin Access for {{curElementType}}
                    </h4>
                </div>
               
            </div>
            <div class="row ps-2 pt-2 m-2 justify-content-center">
              
                <div class="col col-lg-6 col-md-6 col-sm-8 p-1  bg-secondary">
                    <button type="button" class="btn btn-outline-primary  w-100" (click)="openModalNow('create')">Create New Element</button>
                </div>
                <!-- <div class="col col-lg-6 col-md-6 col-sm-8 p-1 bg-secondary">
                    <button type="button" class="btn btn-outline-warning w-100 " (click)="openModalNow('update')">Modify This Element</button>
                </div> -->
            </div>
            <div class="row ps-2 pt-2 m-2 justify-content-center" >
                <div class="col col-lg-6 col-md-6 col-sm-12 p-1 bg-secondary">
                    <button type="button" (click)="getApprovedTotalCount()" class="btn btn-outline-primary  w-100">{{_curApproveCount}}</button>
                </div>
                <div class="col col-lg-6 col-md-6 col-sm-12 p-1 bg-secondary">
                    <button type="button" (click)="getNotApprovedTotalCount()" class="btn btn-outline-primary  w-100">{{_curNotApproveCount}}</button>
                </div>
              
            </div>
            <div class="row ps-2 pt-2 m-2 justify-content-center">
                <div class="col col-lg-6 col-md-6 col-sm-12 p-1 bg-secondary">
                    <button type="button" (click)="createdApprovedState()" class="btn btn-outline-primary  w-100">Created Approved State</button>
                </div>
                <div class="col col-lg-6 col-md-6 col-sm-12 p-1 bg-secondary">
                    <button type="button" (click)="createdNotApprovedState()" class="btn btn-outline-primary  w-100">Created Not-Approved State</button>
                </div>
            </div>
            <div class="row ps-2 pt-2 m-2 justify-content-center" *ngIf="haveSuperAdminAccess()">
              
                <div class="col col-lg-6 col-md-6 col-sm-12 p-1 bg-secondary" *ngIf="canIShowApproveButton()">
                    <button type="button" (click)="approveElement()" class="btn btn-outline-warning  w-100">Approve {{getCurElementName()}}</button>
                </div>
                <div class="col col-lg-6 col-md-6 col-sm-12 p-1 bg-secondary">
                    <button type="button" (click)="deleteElement()" class="btn btn-outline-danger  w-100">Delete {{getCurElementName()}}</button>
                </div>
            </div>
        </div>
    
    </div>
</div>


