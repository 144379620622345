

<div class="row justify-content-center align-items-center pt-5 pb-4"  *ngIf="!isElementPresentNow" >
    <div class="col-sm-12 col-lg-9 col-md-9 ">
        <h4 class="text-warning">We are working on this Please feel free to reach us </h4>
    </div>
</div>
<div class="container-fluid col-lg-10 shadow-lg pb-4 ps-5 pt-1 pe-3 rounded-3 bg-secondary" *ngIf="isElementPresentNow" >

    <div class="row  justify-content-start align-items-start  p-1   " *ngIf="getUrl()!=false">
        <!-- <div class="col-sm-3 col-lg-1 col-md-3  ps-3"></div> -->
        <div class="col-sm-12 col-lg-12 col-md-12  pt-2 pb-3">
      
            <i class="bi text-primary bi-arrow-up-right-square-fill w-300 " (click)="goToLink()">Visit Site {{getUrl()}}</i>
        </div>
    </div>
    <div class="row pe-2 rounded-2 bg-secondary shadow-lg" >
        
        <div class="col-sm-9 col-lg-9 col-md-9">
            <h4 class="text-primary">Details</h4>
        </div>
        
    </div>
    <div class="row pt-3 "></div>
    <div class="row   p-1 bg-secondary shadow-lg" *ngIf="getId()!=false">
        <div class="col-sm-12 col-lg-3 col-md-3">
            <p class="text-primary">Element Id</p>
        </div>
        <div class="col-sm-12 col-lg-9 col-md-9">
            <p >{{getId()}}</p>
        </div>
    </div>
   
    <!-- <div class="row    p-1 bg-secondary shadow-lg " *ngIf="getUrl()!=false">
        <div class="col-sm-12 col-lg-3 col-md-4 ">
            <p class="text-primary">Cur URL</p>
        </div>
        <div class="col-sm-12 col-lg-3 col-md-4">
            <p >{{getUrl()}}</p>
        </div>
    </div> -->

    <div class="row pt-1 "></div>

    <div class=" row  pt-1 pb-1 bg-secondary shadow-lg" *ngIf="getsearchCount()!=false">
        <div class="col-sm-12 col-lg-3 col-md-3">
            <p class="text-primary">Total Search Count </p>
        </div>
        <div class="col-sm-12 col-lg-9 col-md-9">
            <p >{{getsearchCount()}}</p>
        </div>
    </div>
    
    <div class="row pt-1 "></div>
    <div class="row   p-1 bg-secondary shadow-lg " *ngIf="getGender()!=false">
        <div class="col-sm-12 col-lg-3 col-md-4 ">
            <p class="text-primary">Gender</p>
        </div>
        <div class="col-sm-12 col-lg-3 col-md-4">
            <p >{{getGender()}}</p>
        </div>
    </div>
    <div class="row pt-1 "></div>
    <div class="row   p-1 bg-secondary shadow-lg" *ngIf="getContact()!=false">
        <div class="col-sm-12 col-lg-3 col-md-3 ">
            <p class="text-primary">Contact</p>
        </div>
        <div class="col-sm-12 col-lg-9 col-md-9">
            <p >{{getContact()}}</p>
        </div>
    </div>
    <div class="row pt-1 "></div>
    <div class=" row  pt-1 pb-1 bg-secondary shadow-lg" *ngIf="getAddress()!=false">
        <div class="col-sm-3 col-lg-3 col-md-3">
            <p class="text-primary">Address</p>
            <!-- <i class="bi bi-geo-alt-fill"></i> -->
            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="100" height="80" fill="currentColor" class="bi bi-geo-alt-fill text-primary" viewBox="0 0 16 16">
                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6"/>
              </svg> -->
        </div>
        <div class="col-sm-9 col-lg-9 col-md-9 ">
            <p >{{getAddress()}}</p>
        </div>
    </div>
    <div class="row pt-1 "></div>
    <div class="row pt-1 "></div>
        <div class=" row  pt-1 pb-1 " *ngFor="let recipient of getParagraps() | keyvalue">
            <div class="row pt-2 shadow-lg rounded-2 ">
                <div class="col-sm-12 col-lg-3 col-md-3 ">
                    <p class="text-primary"> {{recipient.key}}</p>
                </div>
                <div class="col-sm-12 col-lg-9 col-md-9 " >
                    <div class="row" *ngFor="let parab of getParagrapBreakings(recipient.value) ">
                        <div class="col-sm-12 col-lg-3 col-md-3 "></div>
                        <p >{{parab}}</p>
                    </div>
                    
                </div>
            </div>
            <div class="row pt-1 pb-1"></div>
            
        </div>
    <div class="row pt-1 "></div>
    <div class=" row  pt-1 pb-1 bg-secondary shadow-lg" *ngIf="getApprovedByUser()!=false">
        <div class="col-sm-12 col-lg-3 col-md-3">
            <p class="text-primary">Approved By </p>
        </div>
        <div class="col-sm-12 col-lg-9 col-md-9">
            <p >{{getApprovedByUser()}}</p>
        </div>
    </div>
    <div class="row pt-1 "></div>
    <div class="row   p-1  bg-secondary shadow-lg" *ngIf="getCreatedByUser()!=false">
        <div class="col-sm-12 col-lg-3 col-md-3">
            <p class="text-primary">Created By </p>
        </div>
        <div class="col-sm-12 col-lg-9 col-md-9">
            <p >{{getCreatedByUser()}}</p>
        </div>
    </div>
    <div class="row pt-1 "></div>
    <div class="row   p-1  bg-secondary shadow-lg" *ngIf="getCreated_on()!=false">
        <div class="col-sm-12 col-lg-3 col-md-3">
            <p class="text-primary">Created Time</p>
        </div>
        <div class="col-sm-12 col-lg-9 col-md-9">
            <p >{{getCreated_on()}}</p>
        </div>
    </div>
    <div class="row pt-1 "></div>
    <div class="row   p-1  bg-secondary shadow-lg" *ngIf="getLast_updated()!=false">
        <div class="col-sm-12 col-lg-3 col-md-3">
            <p class="text-primary">Last Updated Time</p>
        </div>
        <div class="col-sm-12 col-lg-9 col-md-9">
            <p >{{getLast_updated()}}</p>
        </div>
    </div>
    <div class="row pt-1 "></div>
    <div class="row" id="tagBox" *ngIf="getAllTags()" >
        <div class="col col-lg-12 col-md-12 col-sm-12 pt-2 pb-2" >
            <label for="allTags">All Tags Added :: </label>
           
        </div>       
        <div class="col col-lg-12 col-md-12 col-sm-12 p-1" ></div>         
        <div class="col " *ngFor="let tag of curElementAllTags">

            <input type="radio" class="btn-check" name="options"autocomplete="off" disabled>
            <label class="btn btn-secondary" for="option3">{{tag}}</label>
        </div> 
    </div>
    <div class="row pt-1 "></div>
    <div class="row" id="etagBox" *ngIf="getAllTags()" >
        <div class="col col-lg-12 col-md-12 col-sm-12 pt-2 pb-2" >
            <label for="allTags">{{elementType}} Tags Added </label>
        </div>      
        <div class="col col-lg-12 col-md-12 col-sm-12 p-1" ></div>     
        <div class="col pb-1" *ngFor="let etag of curElementSpecificTags">
            <input type="radio" class="btn-check" name="options"  autocomplete="off" disabled>
            <label class="btn btn-secondary" for="option3">{{etag}}</label>
        
        </div> 
  
    </div>
</div>


<div style="height: 30px;;">
    
</div>
