import {  Injectable, signal } from '@angular/core';

@Injectable()
export class AdminViewService {

  
  private _selectedElementType=signal("Institute");
  static _serviceReference?:AdminViewService;  
  private _currentAdminElementSearchUrl=signal("");
  private _baseUrl:string|null;
  private _viewType:string="user";

    constructor(){
      this._baseUrl=sessionStorage.getItem("adminelemgmt");
      // !this.updateAdminQuery("allapproved");
    }
 
  static getInstance():AdminViewService{
      if(AdminViewService._serviceReference==undefined||AdminViewService._serviceReference==null){
        AdminViewService._serviceReference=new AdminViewService();
      }
      return AdminViewService._serviceReference;
  }
 
  setElementType(curElementType:string){     
    //alert("Dynamic Search Element Type Set - "+curElementType);      
    this._selectedElementType.set(curElementType);
    this.updateAdminQuery("/allapproved");
  }

  getElementType():string{
    return this._selectedElementType();
  }

  getView(){
    return this._viewType;
  }

  setView(viewName:string){
    this._viewType=viewName;
  }

  
  updateAdminQuery(query:string){
    // this.requestorType="Admin";
    this._currentAdminElementSearchUrl.set(this._baseUrl+""+this._selectedElementType().toString().toLowerCase()+query);
    ////alert("DynamicSearch - updateAdminQuery url change "+this._currentElementSearchUrl());
  }

  updateSameAdminQuery(query:any){
    // this.requestorType="Admin";
    this._currentAdminElementSearchUrl.set(query);
    ////alert("DynamicSearch - updateAdminQuery url change "+this._currentElementSearchUrl());
  }

  getCurrentAdminURL():string{
    return this._currentAdminElementSearchUrl();
  }

}
